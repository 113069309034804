export default () => {
    return {
        availableApps: [
            {label: 'SOS', value: 'SOS'},
            {label: 'Medical', value: 'MEDICAL'},
            {label: 'System', value: 'SYSTEM'},
            {label: 'Fire', value: 'FIRE'},
            {label: 'Water', value: 'WATER'},
            {label: 'Security', value: 'SECURITY'},
        ],
        apps: [],
        app: null,
        orgId: localStorage.getItem('orgId') ?? 1,
        isEditable: false,
        alarmPlans: {
            currentPage: 0,
            count: 0,
            list: [],
            pageSize: 20,
            pagesCount: 1
        },
        alarmPlan: null,
    }
}
