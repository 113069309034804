import request from "@/api/request";
import moment from 'moment-timezone';

/**
 * Use organization id to open exact calendar assign to it.
 * Only GEOGRAPHICAL_SCOPE Organization is able to have Holiday calendar
 * @param orgId
 * @param year
 * @returns {AxiosPromise}
 */
export const getList = (orgId, year = undefined) => {
    let query = {};
    if (year) query = {...query, year};
    query = new URLSearchParams(query);
    return request({
        url: `/holidays/${orgId}?${query.toString()}`,
        method: 'get',
    })
}

export const createItem = (orgId, event, year = undefined) => {
    let query = {};
    if (year) query = {...query, year};
    query = new URLSearchParams(query);
    event = {
        ...event,
        start: moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(event.end).format('YYYY-MM-DD HH:mm:ss')
    };
    return request({
        url: `/holidays/${orgId}?${query.toString()}`,
        method: 'post',
        data: event,
    })
}

export const updateItem = (orgId, event, year = undefined) => {
    let query = {};
    if (year) query = {...query, year};
    query = new URLSearchParams(query);
    event = {
        ...event,
        start: moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(event.end).format('YYYY-MM-DD HH:mm:ss'),
        version: event.version++,
    };
    return request({
        url: `/holidays/${orgId}?${query.toString()}`,
        method: 'put',
        data: event,
    })
}

export const deleteItem = (eventId, orgId, year = undefined) => {
    let query = {};
    if (year) query = {...query, year};
    query = new URLSearchParams(query);
    return request({
        url: `/holidays/${orgId}/${eventId}?${query.toString()}`,
        method: 'delete',
    })
}
