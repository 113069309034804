export const getFileIcon = function (state, filetype) {
    if (state.fileIcons[filetype]) {
        return state.fileIcons[filetype];
    }
    return state.defaultFileIcon;
}

// const parseFilename = function (filename) {
//   const file = filename.split('.');
//   return {
//     ext: file.length > 1 ? file.pop().toLowerCase() : '',
//     name: file.join(file, '.'),
//   }
// }

export default {
    getFileIcon,
}
