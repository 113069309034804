import axios from 'axios'

const axiosApi = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000, // request timeout
})
export const setAuthHeader = (token) => {
    axiosApi.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

setAuthHeader(localStorage.getItem('authtoken'))

export default axiosApi;

