const getLines = state => state.lines;
const getIsEditable = state => state.isEditable;
const getIsLoading = state => state.isLoading;
const getGeneralInfo = state => state.generalInfo;

const getItemsMediaCallsByTypes = state => state.mediaCallsByTypes.items;
const getPeriodMediaCallsByTypes = state => state.mediaCallsByTypes.period;
const getPeriodsMediaCallsByTypes = state => state.mediaCallsByTypes.periods;

const getItemsMediaCalls = state => state.mediaCalls.items;
const getPeriodMediaCalls = state => state.mediaCalls.period;
const getPeriodsMediaCalls = state => state.mediaCalls.periods;

const getItemsStatistics = state => state.statistics.items;
const getPeriodStatistics = state => state.statistics.period;
const getPeriodsStatistics = state => state.statistics.periods;

const getResources = state => state.resources;

export default {
    getLines,
    getIsEditable,
    getIsLoading,
    getGeneralInfo,
    getItemsMediaCallsByTypes,
    getPeriodMediaCallsByTypes,
    getPeriodsMediaCallsByTypes,
    getItemsMediaCalls,
    getPeriodMediaCalls,
    getPeriodsMediaCalls,
    getItemsStatistics,
    getPeriodStatistics,
    getPeriodsStatistics,
    getResources,
}
