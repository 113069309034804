const getHolidays = state => state.holidays;
const getHoliday = state => state.holiday;
const getHolidaysMeta = state => state.holidaysMeta;
const getIsEditable = state => state.isEditable;

export default {
    getHolidays,
    getHoliday,
    getHolidaysMeta,
    getIsEditable,
}
