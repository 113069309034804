export default {
    getFiles: function (state) {
        return state.files
            .filter(i => i.orgId === state.selectedOrg)
            .sort((a, b) => state.ordering === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name));
    },
    getOrganization(state) {
        return state.selectedOrg;
    },
    getIsEditable(state) {
        return state.isEditable;
    },
    getViewMode(state) {
        return state.viewMode;
    },
    getSelectedFiles(state) {
        return state.files
            .filter(i => i.orgId === state.selectedOrg)
            .filter(f => f.selected);
    }
}
