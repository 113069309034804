export default {
    setApp: (state, data) => {
        state.app = data;
    },
    setIsEditable: (state, data) => {
        state.isEditable = data
    },
    setOrgId: (state, data) => {
        state.orgId = data
    },
    setAlarmPlan(state, data) {
        state.alarmPlan = data;
    },
    setAlarmPlans(state, data) {
        state.alarmPlans = {...data, list: data.list.sort((a, b) => a.name.localeCompare(b.name))};
    }
}
