const setTemplates = (state, data) => {
    const {list, ...meta} = data;
    state.templates = list;
    state.meta = meta;
}

const setTemplate = (state, data) => {
    state.template = data;
}

const removeTemplate = (state) => {
    state.templates.splice(state.templates.findIndex(i => i.id === state.template.id), 1);
    state.template = null;
}

const selectTemplate = (state, id) => {
    state.template = null;
    if (id) {
        state.template = state.templates.find(i => i.id === id);
    }
}

export default {
    setTemplates,
    setTemplate,
    removeTemplate,
    selectTemplate,
}
