import request from "@/api/request";

export function getOrg(id) {
    return request({
        url: `/organizations/${id}`,
        method: 'get'
    })
}

export function showOrg(id) {
    return request({
        url: `/showOrg/${id}`,
        method: 'get'
    })
}

export function updateOrg(data) {
    return request({
        url: `/organizations`,
        method: 'put',
        data: data
    })
}

export function getOrgLimits(id) {
    return request({
        url: `/organizations/object-limits/${id}`,
        method: 'get'
    })
}

export function setOrgLimits(id, data) {
    return request({
        url: `/organizations/object-limits/${id}`,
        method: 'post',
        data: data
    })
}

export function createOrg(data) {
    return request({
        url: `/organizations`,
        method: 'post',
        data
    })
}

export function deleteOrg(id) {
    return request({
        url: `/organizations/${id}`,
        method: 'delete'
    })
}

export function moveToOrg(objId, objType, orgId) {
    const data = {
        id: objId,
        objectType: objType,
        newOrgId: orgId
    }
    return request({
        url: `/move`,
        method: 'put',
        data
    })
}
