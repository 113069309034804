const setAlarms = (state, data) => {
    if (data) {
        state.alarms = data;
    } else {
        state.alarms = null;
    }
}

const setAlarm = (state, data) => {
    if (data) {
        state.alarm = data;
    } else {
        state.alarm = null;
    }
}

const setMeta = (state, data) => {
    state.meta = data;
}

export default {
    setAlarms,
    setAlarm,
    setMeta,
}
