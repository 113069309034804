import moment from 'moment-timezone';
import {
  getListCalendars,
  getCalendarById,
  getListEvents,
  getListParticipants,
  createCalendar,
  cloneCalendar,
  updateCalendar,
  removeCalendar,
  getCalendarAssignment
} from '@/api/participant-rotational';

const callCalendars = (context, {orgId, filterValue, currentPage, tableRows}) => {
  context.commit('setCalendars', null);
  getListCalendars(orgId, filterValue, currentPage, tableRows)
    .then(({data, status}) => {
      if (status === 200) {
        context.commit('setCalendars', data);
      } else {
        console.log('Something wrong with calendars');
      }
    });
}

const callCalendarById = (context, calendarId) => {
  context.commit('setCalendar', null);
  if (calendarId) {
    return getCalendarById(calendarId)
      .then(({data, status}) => {
        if (status === 200) {
          context.commit('setCalendar', data);
          return true;
        } else {
          console.log('Something wrong with calendars');
          return false;
        }
      });
  }
}

const callCreateCalendar = (context) => {
  let calendar = context.getters['getCalendar'];
  const events = context.getters['getEvents'];
  if (calendar) {
    if (calendar.id === null) {
      calendar.validFrom = moment(calendar.validFrom, 'YYYY-MM-DD HH:mm:ss').startOf('days').format('YYYY-MM-DD HH:mm:ss');
      return createCalendar(calendar, events)
        .then(({data, status}) => {
          if (status === 200 && data) {
            context.commit('addCalendar', {
              description: data.description,
              id: data.id,
              name: data.name,
              orgId: data.orgId,
              orgName: data.orgName,
              validFrom: data.validFrom,
              version: data.version,
            });
            const { participantWeekGroups, ...calendar } = data;
            context.commit('setEvents', participantWeekGroups);
            context.commit('setCalendar', calendar);
            return true;
          } else {
            console.log('Something is wrong when creating a calendar');
            return false;
          }
        }).catch(error => {
            if (error.response ) {
              if (error.response.data && error.response.data.reason) {
                localStorage.setItem('storedMessage', JSON.stringify({
                  content: error.response.data.reason,
                  severity: 'error',
                  life: 1000
                }))
                callCalendarById(context, calendar.id)
              }
            }
          });
    }
  }
  return false;
}

const callCloneCalendar = (context) => {
  let calendar = context.getters['getCalendar'];
  const events = context.getters['getEvents'];
  if (calendar) {
    return cloneCalendar(calendar, events)
        .then(({data, status}) => {
          if (status === 200 && data) {
            const { participantWeekGroups, ...calendar } = data;
            context.commit('setEvents', participantWeekGroups);
            context.commit('setCalendar', calendar);
            return true;
          } else {
            console.log('Something is wrong when creating a calendar');
            return false;
          }
        }).catch(error => {
          if (error.response ) {
            if (error.response.data && error.response.data.reason) {
              localStorage.setItem('storedMessage', JSON.stringify({
                content: error.response.data.reason,
                severity: 'error',
                life: 1000
              }))
              callCalendarById(context, calendar.id)
            }
          }
        });
  }
  return false;
}

const callUpdateCalendar = (context) => {
  let calendar = context.getters['getCalendar'];
  const events = context.getters['getEvents'];
  if (calendar) {
    if (calendar.id !== null) {
      calendar.validFrom = moment(calendar.validFrom, 'YYYY-MM-DD HH:mm:ss').startOf('days').format('YYYY-MM-DD HH:mm:ss');
      updateCalendar(calendar, events)
        .then(({data, status}) => {
          if (status === 200 && data) {
            context.commit('updateCalendar', {
              description: data.description,
              id: data.id,
              name: data.name,
              orgId: data.orgId,
              orgName: data.orgName,
              validFrom: data.validFrom,
              version: data.version,
            });
              const { participantWeekGroups, ...calendar } = data;
              context.commit('setEvents', participantWeekGroups);
              context.commit('setCalendar', calendar);
          } else {
            console.log('Something is wrong when creating a calendar');
          }
        }).catch(error => {
        if (error.response ) {
          if (error.response.data && error.response.data.reason) {
            localStorage.setItem('storedMessage', JSON.stringify({
              content: error.response.data.reason,
              severity: 'error',
              life: 1000
            }))
            callCalendarById(context, calendar.id)
          }
        }
      });
    }
  }
}

const callRemoveCalendar = (context) => {
  const calendar = context.getters['getCalendar'];
  if (calendar) {
    if (calendar.id) {
      return removeCalendar(calendar.id)
        .then(({status}) => {
          if (status === 200) {
            context.commit('removeCalendar', calendar.id);
            return Promise.resolve(true);
          } else {
            console.log('Something is wrong when deleting a calendar');
            return Promise.reject(false);
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 500) {
            return Promise.reject(`Object "${calendar.name}" is tightly used by others`);
          } else {
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            return Promise.reject(data);
          }
        });
    } else {
      context.commit('selectCalendar', null);
    }
  }
}

const callEvents = (context) => {
  const calendar = context.getters['getCalendar'];
  if (calendar?.id) {
    return getListEvents(calendar?.id)
      .then(({data, status}) => {
        if (status === 200 && data) {
          context.commit('setCalendar', {
            id: data.id,
            description: data.description,
            orgId: data.orgId,
            orgName: data.orgName,
            validFrom: data.validFrom,
            version: data.version,
          });
          data.participantWeekGroups.map(g => {
            g.participantRotationEvents.map(f => {
              if (!f.device) {
                f.device = {
                  "callingDeviceType": null,
                  "message": null
                }
              }
            });
          });
          // console.log(data.participantWeekGroups)
          context.commit('setEvents', data.participantWeekGroups);
          return true;
        } else {
          console.log('Something wrong with events');
        }
        return true;
      });
  }
}

const callParticipants = (context, orgId) => {
  return getListParticipants(orgId)
    .then(({data, status}) => {
      if (status === 200) {
        context.commit('setParticipants', data);
        return true;
      } else {
        console.log('Something wrong with calendars');
      }
      return false;
    });
}

const callAssignment = (context, { page = 0, pageSize = 20 }) => {
  return getCalendarAssignment(context.state.calendar.id, page, pageSize)
    .then(({ data, status }) => {
      if (status === 200) {
        context.commit('setAssignment', data);
      }
    });
}

export default {
  callCalendars,
  callCalendarById,
  callCreateCalendar,
  callUpdateCalendar,
  callRemoveCalendar,
  callCloneCalendar,
  callEvents,
  callParticipants,
  callAssignment,
}
