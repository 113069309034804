export const googleMapOption = {
    load: {
        key: 'AIzaSyBBdZ4eG44IWDAYk4yOM24mCHQlc1MzDWg',
    }
}

export const alarmJobStatuses = ['NEW', 'CONFIG_PROBLEM', 'INACTIVE', 'FAILURE', 'FINISHED', 'INTERRUPTED',
    'CANCELED', 'NOT_ACCEPTED', 'NOT_ACKNOWLEDGED', 'PARTIAL_ACCEPTED', 'ACCEPTED',
    'PROCESSING', 'PARTIAL_ACKNOWLEDGED', 'ACKNOWLEDGED', 'SCHEDULED',
];

export const alarmTableFilterKeys = [
    {
        label: 'None',
        value: ''
    },
    {
        label: 'Name',
        value: 'alarmPointName',
    },
    {
        label: 'Alarm Point Id',
        value: 'alarmPointId',
    },
    {
        label: 'System ID',
        value: 'alarmPointSysId',
    },
    {
        label: 'Triggered By',
        value: 'triggeredBy',
    },
    {
        label: 'Alarm Job Status',
        value: 'alarmJobStatus',
    },
];

export const downloadExcelItems = [
    // {
    //   label: 'PDF ',
    //   icon: 'pi pi-file-pdf',
    //   command: () => {
    //     this.getFile('pdf')
    //   }
    // },
    {
        label: 'Excel',
        icon: 'pi pi-file-excel',
        type: 'excel'
    },
    {
        label: 'Excel datatable',
        icon: 'pi pi-file-excel',
        type: 'excel-datatable'
    },
    {
        label: 'Excel detailed',
        icon: 'pi pi-file-excel',
        type: 'excel-detailed'
    },
];

export const downloadPdfItems = [
    // {
    //   label: 'PDF ',
    //   icon: 'pi pi-file-pdf',
    //   command: () => {
    //     this.getFile('pdf')
    //   }
    // },
    {
        label: 'PDF',
        icon: 'pi pi-file-pdf',
        type: 'pdf'
    },
    // {
    //   label: 'PDF datatable',
    //   icon: 'pi pi-file-pdf',
    //   type: 'pdf-datatable'
    // },
    {
        label: 'PDF detailed',
        icon: 'pi pi-file-pdf',
        type: 'pdf-detailed'
    },
];

export const englishPeriods = {
    days: [
        {name: 'today', value: 'Today'},
        {name: 'yesterday', value: 'Yesterday'},
        {name: 'last7days', value: 'Last 7 days'},
        {name: 'last28days', value: 'Last 28 days'},
    ],
    weeks: [
        {name: 'thisWeek', value: 'This week'},
        {name: 'lastWeek', value: 'Last week'},
        {name: 'thisMonth', value: 'This month'},
        {name: 'lastMonth', value: 'Last month'},
    ],
    quarts: [
        {name: 'thisQuarter', value: 'This quarter'},
        {name: 'lastQuarter', value: 'Last quarter'},
        {name: 'thisYear', value: 'This year'},
    ],
}

export const deutschPeriods = {
    days: [
        {name: 'today', value: 'Heute'},
        {name: 'yesterday', value: 'Gestern'},
        {name: 'last7days', value: 'Letzte 7 Tage'},
        {name: 'last28days', value: 'Letzte 28 Tage'},
    ],
    weeks: [
        {name: 'thisWeek', value: 'Diese Woche'},
        {name: 'lastWeek', value: 'Letzte Woche'},
        {name: 'thisMonth', value: 'Diesen Monat'},
        {name: 'lastMonth', value: 'Letzter Monat'},
    ],
    quarts: [
        {name: 'thisQuarter', value: 'Dieses Quartal'},
        {name: 'lastQuarter', value: 'Letztes Quartal'},
        {name: 'thisYear', value: 'Diesjährig'},
    ],
}

export const frenchPeriods = {
    days: [
        {name: 'today', value: 'Aujourd'},
        {name: 'yesterday', value: 'Hier'},
        {name: 'last7days', value: '7 derniers jours'},
        {name: 'last28days', value: '28 derniers jours'},
    ],
    weeks: [
        {name: 'thisWeek', value: 'Cette Semaine'},
        {name: 'lastWeek', value: 'La semaine dernière'},
        {name: 'thisMonth', value: 'Ce mois-ci'},
        {name: 'lastMonth', value: 'Le Mois Dernier'},
    ],
    quarts: [
        {name: 'thisQuarter', value: 'Ce Trimestre'},
        {name: 'lastQuarter', value: 'Dernier trimestre'},
        {name: 'thisYear', value: 'Cette Année'},
    ],
}

export const italianPeriods = {
    days: [
        {name: 'today', value: 'Oggi'},
        {name: 'yesterday', value: 'Ieri'},
        {name: 'last7days', value: 'Ultimi 7 giorni'},
        {name: 'last28days', value: 'Ultimi 28 giorni'},
    ],
    weeks: [
        {name: 'thisWeek', value: 'Questa settimana'},
        {name: 'lastWeek', value: 'La settimana scorsa'},
        {name: 'thisMonth', value: 'Questo mese'},
        {name: 'lastMonth', value: 'Il mese scorso'},
    ],
    quarts: [
        {name: 'thisQuarter', value: 'Questo trimestre'},
        {name: 'lastQuarter', value: 'Ultimo trimestre'},
        {name: 'thisYear', value: "Quest'anno"},
    ],
}

export const initialDashboardItems = [
    [],
    [],
    [],
    [],
    [],
    [
        {
            key: 'menuItem',
            config: {
                icon: "mdi mdi-gesture-double-tap",
                key: "menu.trigger_alarm",
                label: "Alarm panels",
                to: "/alarm-panels"
            }
        },
        {
            key: 'menuItem',
            config: {
                icon: "mdi mdi-paperclip",
                key: "menu.attachments",
                label: "Alarm attachments",
                to: "/attachments",
            }
        },
        {
            key: 'menuItem',
            config: {
                icon: "mdi mdi-clipboard-edit",
                key: "menu.monthly_reports",
                label: "Monthly reports",
                to: "/reports/monthly",
            }
        },
        {
            key: 'menuItem',
            config: {
                icon: "mdi mdi-clipboard-edit-outline",
                key: "menu.detailed_reports",
                label: "Detailed reports",
                to: "/reports/detailed",
            }
        },
        {
            key: 'menuItem',
            config: {
                icon: "mdi mdi-factory",
                key: "menu.organizations",
                label: "Organizations",
                to: "/organizations"
            }
        },
    ],
    [{key: "lastAlarms", config: {}}],
    [],
    [
        {key: 'statistics', config: {}},
        {key: 'mediaCallsByTypes', config: {}},
        {key: 'generalInfo', config: {}},
        {key: 'mediaCalls', config: {}}
    ],
    [],
    [],
    [
        {key: 'resources', config: {key: 'memoryUsage', name: 'label_Memory_usage'}},
        {key: 'resources', config: {key: 'diskUsage', name: 'label_Disk_usage'}},
        {key: 'resources', config: {key: 'sysCpuLoad', name: 'label_System_CPU_load'}},
        {key: 'resources', config: {key: 'cpuLoad', name: 'label_CPU_load'}},
        {key: 'resources', config: {key: 'cpuTime', name: 'label_CPU_time'}},
    ],
]

export const initialColsData = {
    'alarmParticipantGroupsItems': [
        {
            value: 'name',
            label: 'Name',
            checked: ['name'],
        },
        {
            value: 'organization',
            label: 'ORGANIZATION',
            checked: ['organization']
        },
        {
            value: 'participants',
            label: 'label_participants',
            checked: ['participants'],
        },
        {
            value: 'alarms',
            label: 'label_Alarms',
            checked: ['alarms']
        }
    ],
    'teamMediaSchedulesItems': [
        {
            value: 'name',
            label: 'Name',
            checked: ['name'],
        },
        {
            value: 'description',
            label: 'label.Description',
            checked: ['description'],
        },
        {
            value: 'orgName',
            label: 'ORGANIZATION',
            checked: ['orgName'],
        },
        {
            value: 'validFrom',
            label: 'label_valid_from',
            checked: ['validFrom'],
        },
        {
            value: 'rotation',
            label: 'label_rotation',
            checked: ['rotation'],
        },
        {
            value: 'assignment',
            label: 'label_assignment',
            checked: ['assignment'],
        },
    ],
    'standbySchedulesItems': [
        {
            value: 'name',
            label: 'Name',
            checked: ['name'],
        },
        {
            value: 'description',
            label: 'label.Description',
            checked: ['description'],
        },
        {
            value: 'orgName',
            label: 'ORGANIZATION',
            checked: ['orgName'],
        },
        {
            value: 'validFrom',
            label: 'label_valid_from',
            checked: ['validFrom'],
        },
        {
            value: 'rotation',
            label: 'label_rotation',
            checked: ['rotation'],
        },
        {
            value: 'assignment',
            label: 'label_assignment',
            checked: ['assignment'],
        },
    ],
    'alarmParticipantPlansItems': [
        {
            value: 'name',
            label: 'Name',
            checked: ['name'],
        },
        {
            value: 'organization',
            label: 'ORGANIZATION',
            checked: ['organization']
        },
        {
            value: 'participants',
            label: 'label_participants',
            checked: ['participants'],
        },
        {
            value: 'alarms',
            label: 'label_Alarms',
            checked: ['alarms']
        }
    ],
    'alarmParticipantsItems': [
        {
            value: 'surname',
            label: 'label_short_name',
            checked: ['surname'],
        },
        {
            value: 'name',
            label: 'Name',
            checked: ['name'],
        },
        {
            value: 'shortName',
            label: 'label_short_name',
            checked: ['shortName'],
        },
        {
            value: 'id',
            label: 'ID',
            checked: ['id'],
        },
        {
            value: 'orgName',
            label: 'ORGANIZATION',
            checked: ['orgName'],
        },
        {
            value: 'media',
            label: 'label_media',
            checked: ['media'],
        },
        {
            value: 'teamMediaSchedules',
            label: 'menu.team_media_schedules',
            checked: ['teamMediaSchedules'],
        },
    ],
    'alarmPointItems': [
        {
            value: 'name',
            label: 'label_object_name',
            checked: ['name'],
        },
        {
            value: 'alarmId',
            label: 'label_alarm_id',
            checked: ['alarmId'],
        },
        {
            value: 'alarmPlan',
            label: 'label_alarm_plan',
            checked: ['alarmPlan'],
        },
        {
            value: 'systemId',
            label: 'label_system_id',
            checked: ['systemId'],
        },
        {
            value: 'alarmStatus',
            label: 'ALARMSTATUS',
            checked: ['alarmStatus'],
        },
        {
            value: 'mode',
            label: 'Mode',
            checked: ['mode'],
        },
        {
            value: 'orgName',
            label: 'ORGANIZATION',
            checked: ['orgName'],
        },
        {
            value: 'icon',
            label: 'label_Icon',
            checked: ['icon'],
        },
        {
            value: 'schedule',
            label: 'ALARM_POINT_ROTATIONAL_CAL',
            checked: ['schedule'],
        },
    ],
    'alarmTableItems': [
        {
            value: 'alarmName',
            label: 'label_Alarm_name',
            checked: ['alarmName'],
        },
        {
            value: 'status',
            label: 'label_status',
            checked: ['status'],
        },
        {
            value: 'triggeredBy',
            label: 'filter_triggeredby',
            checked: ['triggeredBy'],
        },
        {
            value: 'alarmId',
            label: 'label_alarm_id',
            checked: ['alarmId'],
        },
        {
            value: 'started',
            label: 'label_started',
            checked: ['started'],
        },
        {
            value: 'going',
            label: 'label_going',
            checked: ['going'],
        },
        {
            value: 'systemId',
            label: 'label_system_id',
            checked: ['systemId'],
        }
    ]
}
