import request from "@/api/request";

export function getAlarmPoint(id) {
    return request({
        url: `/alarmpoints/${id}`,
        method: 'get'
    })
}

export function createAlarmPoint(data) {
    return request({
        url: `/alarmpoints`,
        method: 'post',
        data: data
    })
}

export function updateAlarmPoint(data) {
    return request({
        url: `/alarmpoints/`,
        method: 'put',
        data: data
    })
}

export function deleteAlarmPoint(id) {
    return request({
        url: `/alarmpoints/${id}`,
        method: 'delete'
    })
}

export function getAlarmPointCalendar(alarmPointId) {
    return request({
        url: `/alarmpoints/${alarmPointId}/calendar`,
        method: 'get'
    })
}

export function createUpdateEvent(alarmPointId, data) {
    return request({
        url: `/alarmpoints/${alarmPointId}/calendar/update`,
        method: 'put',
        data,
    })
}

export function cloneAlarmPoint(data) {
    return request({
        url: `/alarmpoints/clone`,
        method: 'post',
        data,
    })
}

export function restoreAlarmPoint(data) {
    return request({
        url: `/alarmpoints/re-enable`,
        method: 'put',
        data,
    })
}

export function deleteEvent(alarmPointId, sourceEventId) {
    return request({
        url: `/alarmpoints/${alarmPointId}/calendar/${sourceEventId}`,
        method: 'delete',
    })
}

export function textToSpeech(text) {
    return request({
        url: `/alarmpoints/text-prelisten-link`,
        method: 'post',
        headers: {
            "Content-Type": "application/json"
        },
        data: JSON.stringify(text.replace(/[\n\r]+/g, ' '))
    })
}

