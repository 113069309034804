import moment from 'moment-timezone';
import {
    getListCalendars,
    getCalendarById,
    getListEvents,
    getListAlarmPlans,
    createCalendar,
    updateCalendar,
    removeCalendar,
    getCalendarAssignment
} from '@/api/alarm-rotational';

const callCalendars = (context, {orgId, filterValue, currentPage, tableRows}) => {
    context.commit('setCalendars', null);
    return getListCalendars(orgId, filterValue, currentPage, tableRows)
        .then(({data, status}) => {
            if (status === 200) {
                context.commit('setCalendars', data);
                return Promise.resolve(data);
            } else {
                return Promise.reject('Something goes wrong');
            }
        }).catch(error => {
            if (error.response ) {
                if (error.response.data && error.response.data.reason) {
                    localStorage.setItem('accessDeniedReason', error.response.data.reason);
                    return Promise.reject(error.response.data.reason);
                }
            }
        });
}

const callCalendarById = (context, calendarId) => {
    context.commit('setCalendar', null);
    if (calendarId) {
        return getCalendarById(calendarId)
            .then(({data, status}) => {
                if (status === 200) {
                    context.commit('setCalendar', data);
                    return true;
                } else {
                    console.log('Something wrong with calendars');
                    return false;
                }
            });
    }
}

const callCreateCalendar = (context) => {
    const calendar = context.getters['getCalendar'];
    const events = context.getters['getEvents'];
    if (calendar) {
        if (calendar.id === null) {
            if (moment(calendar.validFrom, 'DD.MM.YY').isValid()) {
                calendar.validFrom = moment(calendar.validFrom, 'DD.MM.YY').startOf('days').format('YYYY-MM-DD HH:mm:ss');
            }
            return createCalendar(calendar, events)
                .then(({data, status}) => {
                    if (status === 200 && data) {
                        context.commit('addCalendar', {
                            description: data.description,
                            id: data.id,
                            name: data.name,
                            orgId: data.orgId,
                            orgName: data.orgName,
                            validFrom: data.validFrom,
                            version: data.version,
                        });
                        const {alarmPointWeekGroups, ...calendar} = data;
                        context.commit('setEvents', alarmPointWeekGroups);
                        context.commit('setCalendar', calendar);
                        return true;
                    } else {
                        console.log('Something is wrong when creating a calendar');
                        return false;
                    }
                }).catch(error => {
                    if (error.response ) {
                        if (error.response.data && error.response.data.reason) {
                            localStorage.setItem('storedMessage', JSON.stringify({
                                content: error.response.data.reason,
                                severity: 'error',
                                life: 1000
                            }))
                            callCalendars(context, localStorage.getItem('orgId'))
                            callCalendarById(context, calendar.id)
                        }
                    }
                });
        }
    }
    return false;
}

const callUpdateCalendar = (context) => {
    const calendar = context.getters['getCalendar'];
    const events = context.getters['getEvents'];
    if (calendar) {
        if (calendar.id !== null) {
            updateCalendar(calendar, events)
                .then(({data, status}) => {
                    if (status === 200 && data) {
                        context.commit('updateCalendar', {
                            description: data.description,
                            id: data.id,
                            name: data.name,
                            orgId: data.orgId,
                            orgName: data.orgName,
                            validFrom: data.validFrom,
                            version: data.version,
                        });
                        const {alarmPointWeekGroups, ...calendar} = data;
                        context.commit('setEvents', alarmPointWeekGroups);
                        context.commit('setCalendar', calendar);
                    } else {
                        localStorage.setItem('storedMessage', JSON.stringify({
                            content: data.reason,
                            severity: 'error',
                            life: 1000
                        }))
                        console.log('Something is wrong when creating a calendar');
                    }
                }).catch(error => {
                if (error.response ) {
                    if (error.response.data && error.response.data.reason) {

                        localStorage.setItem('storedMessage', JSON.stringify({
                            content: error.response.data.reason,
                            severity: 'error',
                            life: 1000
                        }))
                        callCalendars(context, localStorage.getItem('orgId'))
                        callCalendarById(context, calendar.id)
                    }
                }
            });
        }
    }
}

const callRemoveCalendar = (context) => {
    const calendar = context.getters['getCalendar'];
    if (calendar) {
        if (calendar.id) {
            return removeCalendar(calendar.id)
                .then(({status}) => {
                    if (status === 200) {
                        context.commit('removeCalendar', calendar.id);
                        return Promise.resolve(true);
                    } else {
                        console.log('Something is wrong when deleting a calendar');
                        return Promise.reject(false);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 500) {
                        return Promise.reject(`Object "${calendar.name}" is tightly used by others`);
                    } else {
                        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
                        return Promise.reject(data);
                    }
                });
        } else {
            context.commit('selectCalendar', null);
        }
    }
}

const callEvents = (context) => {
    const calendar = context.getters['getCalendar'];
    if (calendar?.id) {
        return getListEvents(calendar?.id)
            .then(({data, status}) => {
                if (status === 200 && data) {
                    context.commit('setCalendar', {
                        id: data.id,
                        description: data.description,
                        orgId: data.orgId,
                        orgName: data.orgName,
                        validFrom: data.validFrom,
                        version: data.version,
                    });
                    context.commit('setEvents', data.alarmPointWeekGroups);
                    return true;
                } else {
                    console.log('Something wrong with events');
                }
                return true;
            });
    }
}

const callAlarmPlans = (context, orgId) => {
    return getListAlarmPlans(orgId)
        .then(({data, status}) => {
            if (status === 200) {
                context.commit('setAlarmPlans', data);
                return true;
            } else {
                console.log('Something wrong with calendars');
            }
            return false;
        });
}

const callAssignment = (context, {page = 0, pageSize = 20}) => {
    return getCalendarAssignment(context.state.calendar.id, page, pageSize)
        .then(({data, status}) => {
            if (status === 200) {
                context.commit('setAssignment', data);
            }
        });
}

export default {
    callCalendars,
    callCalendarById,
    callCreateCalendar,
    callUpdateCalendar,
    callRemoveCalendar,

    callEvents,

    callAlarmPlans,

    callAssignment,
}
