<template>
  <div v-if="editMode" class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-sitemap"></i>
      </span>
    <Button :label="cropRoot(selectedOrganization.fullOrgPath) + (requiredField ? ' *' : '')"
            class="p-button-outlined p-button-success"
            @click="selectOrganizationDialog = true"/>
  </div>
  <Button v-else
          :label="cropRoot(selectedOrganization.fullOrgPath)" c
          lass="p-button-link text"
          @click="toOrg(selectedOrganization.id)"/>

  <Dialog v-model:visible="selectOrganizationDialog"
          :modal="true"
          style="width: 30em;"
          @close="selectOrganizationDialog = false">
    <template #header>
      <h3>{{ _t('label_organization') }}</h3>
    </template>
    <TreeTable
        v-model:expandedKeys="expandedOrganizations"
        v-model:selectionKeys="selectedNode"
        :value="organizations"
        autoLayout
        class="p-treetable-sm"
        scrollable
        selectionMode="single"
        stripedRows
        style="height: 30em; overflow-y: auto;"
        @node-select="selectNode($event)"
        @node-expand="appendNode($event)"
    >
      <Column :expander="true" field="name" headerStyle="display: none;"/>
    </TreeTable>
    <template #footer>
      <div class="text-right">
        <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary"
                @click="selectOrganizationDialog = false"/>
        <Button :label="_t('Select')" autofocus class="p-button-success" @click="selectOrganization()"/>
      </div>
    </template>
  </Dialog>
</template>

<style scoped>
.text {
  font-size: 12px;
  padding: 0;
}
</style>

<script>
import {getTree} from '@/api/tree'
import router from '@/router'
import {moveToOrg, showOrg} from '@/api/organization'


export default {
  name: 'OrganizationLink',
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    modelValue: {},
    requiredField: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      default: ''
    }
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      selectOrganizationDialog: false,
      expandedOrganizations: [],
      selectedNode: [],
      selectedOrganization: {
        id: null,
        name: '...',
        fullOrgPath: '...'
      },
      organizations: [],
      dirty: false
    }
  },
  watch: {
    'modelValue.orgId': function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let orgId = this.modelValue && this.modelValue.orgId ? this.modelValue.orgId : localStorage.getItem('orgId')
      if (orgId) {
        showOrg(orgId).then((response) => {
          this.selectedOrganization = {
            id: response.data.id,
            name: response.data.name,
            fullOrgPath: response.data.fullOrgPath.replaceAll(']-[', ' > ').replaceAll('[', '').replaceAll(']', '')
          }
          this.expandedOrganizations = []
          this.selectedNode = []
          this.selectedNode[response.data.id] = true

          this.getOrganizations()
        })
      }
    },
    getOrganizations() {
      getTree().then((response) => {
        this.organizations = response.data.map(item => {
          const container = {
            key: item.id,
            leaf: !item.hasChildren,
            data: {
              name: item.name,
              parentNames: []
            }
          }
          return container
        }, this)
      })
    },
    toOrg(id) {
      localStorage.setItem('orgId', id)
      router.push(`/organizations/${id}`)
    },
    selectOrganization() {
      this.updateModel()
      this.selectOrganizationDialog = false
    },
    updateModel() {
      if (this.modelValue.orgId != this.selectedOrganization.id) {
        showOrg(this.selectedOrganization.id).then((response) => {
          this.selectedOrganization = {
            id: response.data.id,
            name: response.data.name,
            fullOrgPath: response.data.fullOrgPath.replaceAll(']-[', ' > ').replaceAll('[', '').replaceAll(']', '')
          }

          let model = this.modelValue
          model.orgId = this.selectedOrganization.id
          model.orgName = this.selectedOrganization.name
          this.dirty = true
          this.$emit('update:modelValue', model)
          this.$emit('change', this.selectedOrganization.id, this.selectedOrganization.name)
        })
      }
    },
    moveToOrg(callback = null, params = []) {
      if (this.dirty && this.modelType && this.modelType != '' && this.modelValue.id && this.modelValue.id !== null && !this.modelValue.id.toString().startsWith('_')) {
        params.push(true)
        moveToOrg(this.modelValue.id, this.modelType, this.selectedOrganization.id).then((response) => {
          if (response.data.status && response.data.status == 'ok') {
            if (callback) {
              callback(...params)
            }
            this.dirty = false
          } else {
            let data = response.data ? response.data instanceof Object ? response.data : JSON.parse(response.data) : {}
            this.$root.showMessage('Unable to change organization' + (data.reason ? ': ' + data.reason : ''), 'error')
          }
        }).catch((error) => {
          let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
          this.$root.showMessage('Unable to change organization' + (data.reason ? ': ' + data.reason : ''), 'error')
        })
      } else {
        params.push(false)
        if (callback) {
          callback(...params)
        }
        this.dirty = false
      }
    },
    selectNode(item) {
      if (item && item.key != this.selectedOrganization.id) {
        let path = [...item.data.parentNames]
        path.push(item.data.name)
        this.selectedOrganization = {
          id: item.key,
          name: item.data.name,
          fullOrgPath: path.join(' > ')
        }
      }
    },
    appendNode(item) {
      if (item !== null && item.data !== null) {
        let names = []
        if (item.key != 1) {
          names = item.data.parentNames.length > 0 ?
              [...item.data.parentNames, item.data.name] :
              [item.data.name]
        }
        getTree(item.key, this.treeParams).then((response) => {
          item.children = response.data.map(element => {
            const container = {
              key: element.id,
              leaf: !element.hasChildren,
              data: {
                name: element.name,
                parentNames: names
              }
            }
            return container
          })
        })
      }

    },
    cropRoot(name) {
      return name ? name.replace('Total system > ', '') : ''
    }
  }
}
</script>
