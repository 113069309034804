import {changePassword, getUser, updateUser} from '@/api/user';

const callUser = (context, id) => {
    return getUser(id)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    context.commit('setUser', data);
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(false);
                }
            },
            error => {
                console.log(error);
                return Promise.reject(error.message);
            },
        );
}

const callUpdateUser = (context) => {
    return updateUser(context.state.user)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    context.commit('setUser', data);
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(false);
                }
            },
            error => {
                console.log(error);
                let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
                return Promise.reject(data.reason);
            },
        );
}

const callChangePassword = (context, {oldPassword, newPassword}) => {
    return changePassword(oldPassword, newPassword)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    context.commit('setUser', data);
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(false);
                }
            },
            error => {
                console.log(error);
                let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
                return Promise.reject(data.reason);
            },
        );
}

export default {
    callUser,
    callUpdateUser,
    callChangePassword,
}
