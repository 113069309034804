export default () => {
    return {
        holidays: [],
        holiday: null,
        isEditable: false,
        holidaysMeta: {
            id: null,
            version: 0,
            orgId: 1,
            orgName: "Total system",
            name: "Total system",
            validFrom: null,
            description: null,
            year: 1970,
        },
    }
}
