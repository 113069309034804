import {createItem, deleteItem, getList, updateItem} from '@/api/holidays';

const callCreateHoliday = (context, orgId) => {
    createItem(orgId, context.state.holiday)
        .then(({data, status}) => {
            if (status === 200) {
                context.commit('setHolidays', data);
            } else {
                console.log('Something wrong with holidays');
            }
        }).catch(error => {
        if (error.response.data.reason === 'Date is occupied') {
            localStorage.setItem('storedMessage', JSON.stringify({
                content: error.response.data.reason,
                severity: 'error',
                life: 5000
            }))
        } else {
            if (error.response.data && error.response.data.reason) {
                localStorage.setItem('storedMessage', JSON.stringify({
                    content: error.response.data.reason,
                    severity: 'error',
                    life: 5000
                }))
                callGetHolidays(context, localStorage.getItem('orgId'))
            }
        }
    });
}

const callUpdateHoliday = (context, orgId) => {
    updateItem(orgId, context.state.holiday)
        .then(({data, status}) => {
            if (status === 200 && data) {
                context.commit('updateHoliday', {
                    title: data.title,
                    id: data.id,
                    active: data.active,
                    allDay: data.allDay,
                    start: data.start,
                    end: data.end,
                    recurring: data.recurring,
                    standard: data.standard,
                });
            } else {
                console.log('Something is wrong when updating a holiday');
            }
        }).catch(error => {
        if (error.response) {
            if (error.response.data && error.response.data.reason) {
                localStorage.setItem('storedMessage', JSON.stringify({
                    content: error.response.data.reason,
                    severity: 'error',
                    life: 1000
                }))
                callGetHolidays(context, orgId)
            }
        }
    });
}

const callDeleteHoliday = (context, orgId) => {
    if (context.state.holiday) {
        if (context.state.holiday.id) {
            deleteItem(context.state.holiday.id, orgId)
                .then(({status}) => {
                    if (status === 200) {
                        context.commit('removeHoliday', context.state.holiday.id);
                    } else {
                        console.log('Something is wrong when deleting a holiday');
                    }
                });
        } else {
            context.commit('selectHoliday', null);
        }
    }
}

const callGetHolidays = (context, {orgId, year}) => {
    if ((year && context.state.holidaysMeta?.year === year)
        && (orgId && context.state.holidaysMeta?.orgId.toString() === orgId.toString())
        && context.state.holidays.length
    ) {
        return true;
    } else {
        context.commit('setHolidays', []);
        return getList(orgId, year)
            .then(({data, status}) => {
                if (status === 200) {
                    context.commit('setHolidays', data);
                    return true;
                } else {
                    console.log('Something wrong with holidays');
                    return false;
                }
            });
    }
}

export default {
    callCreateHoliday,
    callUpdateHoliday,
    callDeleteHoliday,
    callGetHolidays,
}
