import {
    getInstructionFiles,
    moveInstructionFiles,
    multiplyUploadInstructionFiles,
    removeInstructionFiles,
    uploadInstructionFile
} from '@/api/attachments';
import {getFileIcon} from './helper';

const callFiles = (context, filter) => {
    return getInstructionFiles(filter)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    const files = [];
                    data.instructions.map(item => item.files.map(file => {
                        files.push({
                            name: file.name,
                            path: file.path,
                            ext: file.type,
                            icon: getFileIcon(context.state, file.type),
                            selected: false,
                            hovered: false,
                            orgId: parseInt(item.org.id),
                            orgName: item.org.name,
                        });
                    }));
                    context.commit('setFiles', files.sort((a, b) => a.name.localeCompare(b.name)));
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(false);
                }
            },
            error => {
                console.log(error);
                return Promise.reject(error.message);
            },
        );
}

const removeFiles = (context) => {
    const files = context.state.files
        .filter(i => i.orgId === context.state.selectedOrg)
        .filter(f => f.selected);
    return removeInstructionFiles(files)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    files.map(f => {
                        context.commit('removeFile', f);
                    });
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            },
            error => {
                console.log(error);
                return Promise.reject(error.message);
            },
        );
}

const uploadFile = (context, formData) => {
    return uploadInstructionFile(formData)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    context.commit('addFile', data);
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            },
            error => {
                console.log(error);
                return Promise.reject(error);
            },
        );
}

const multiplyUpload = (context, formData) => {
    return multiplyUploadInstructionFiles(formData)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    data.map(file => {
                        context.commit('addFile', file);
                    })
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            },
            error => {
                console.log(error);
                return Promise.reject(error.message);
            },
        );
}

const moveToOrganization = (context, orgId) => {
    orgId = orgId ?? 1;
    const files = context.state.files
        .filter(i => i.orgId === context.state.selectedOrg)
        .filter(f => f.selected);
    return moveInstructionFiles(files, orgId)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    context.commit('moveToOrg', {files, orgId})
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            },
            error => {
                console.log(error);
                return Promise.reject(error.message);
            }
        );
}

export default {
    callFiles,
    removeFiles,
    uploadFile,
    multiplyUpload,
    moveToOrganization,
}
