import locale from './locale';
import participant from './participant';
import rotationalCalendar from './rotaional-calendar';
import participantCalendar from './participant-calendar';
import individualCalendar from './individual-calendar';
import holidays from './holidays';
import devices from './devices';
import alarmpoints from './alarmpoints';
import dashboard from './dashboard';
import alarmTable from './alarmtable';
import users from './users';
import alarmTemplates from './alarm-templates';
import attachments from './attachments';
import consequentials from './consequentials';
import apps from './apps';

export default {
  locale,
  participant,
  rotationalCalendar,
  participantCalendar,
  individualCalendar,
  holidays,
  devices,
  alarmpoints,
  dashboard,
  alarmTable,
  users,
  alarmTemplates,
  attachments,
  consequentials,
  apps,
}
