import {getAll} from '@/api/alarmTable';

const callAlarms = (context) => {
    context.commit('setAlarm', null);
    context.commit('setAlarms', []);
    return getAll(context.state.meta.currentPage, '', null, context.state.meta.pageSize, context.state.meta.columnsFilter, context.state.meta.organizationFilter, context.state.meta.duration, false).then(
        res => {
            const {count, currentPage, list, pageSize, pagesCount} = res.data;
            context.commit('setAlarms', list);
            context.commit('setMeta', {
                ...context.state.meta,
                count, currentPage, pageSize, pagesCount,
            });
            return Promise.resolve(list);
        },
        error => {
            return Promise.reject(error);
        }
    );
}

export default {
    callAlarms,
}
