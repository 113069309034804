<template>
  {{ this.find() }}
</template>
<script>
export default {
  name: 'SelectedOption',
  props: {
    options: {
      type: Array
    },
    modelValue: {},
    optionLabel: {
      type: String
    },
    optionValue: {
      type: String
    },
    default: {
      type: String
    }
  },
  methods: {
    find() {
      const found = this.optionValue ? this.options.find(item => item[this.optionValue] == this.modelValue) : this.options.find(item => item == this.modelValue)
      if (found) {
        return this.optionLabel ? found[this.optionLabel] : found
      }
      return this.default ?? ''
    }
  }
}
</script>
