export const englishLocale = {
  startsWith: "Starts with",
  contains: "Contains",
  notContains: "Not contains",
  endsWith: "Ends with",
  equals: "Equals",
  notEquals: "Not equals",
  noFilter: "No Filter",
  lt: "Less than",
  lte: "Less than or equal to",
  gt: "Greater than",
  gte: "Greater than or equal to",
  dateIs: "Date is",
  dateIsNot: "Date is not",
  dateBefore: "Date is before",
  dateAfter: "Date is after",
  clear: "Clear",
  apply: "Apply",
  matchAll: "Match All",
  matchAny: "Match Any",
  addRule: "Add Rule",
  removeRule: "Remove Rule",
  accept: "Yes",
  reject: "No",
  choose: "Choose",
  upload: "Upload",
  cancel: "Cancel",
  completed: "Completed",
  pending: "Pending",
  dayNames: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  chooseYear: "Choose Year",
  chooseMonth: "Choose Month",
  chooseDate: "Choose Date",
  prevDecade: "Previous Decade",
  nextDecade: "Next Decade",
  prevYear: "Previous Year",
  nextYear: "Next Year",
  prevMonth: "Previous Month",
  nextMonth: "Next Month",
  prevHour: "Previous Hour",
  nextHour: "Next Hour",
  prevMinute: "Previous Minute",
  nextMinute: "Next Minute",
  prevSecond: "Previous Second",
  nextSecond: "Next Second",
  am: "am",
  pm: "pm",
  today: "Today",
  weekHeader: "Wk",
  firstDayOfWeek: 0,
  dateFormat: "mm/dd/yy",
  weak: "Weak",
  medium: "Medium",
  strong: "Strong",
  passwordPrompt: "Enter a password",
  emptyFilterMessage: "No results found",
  searchMessage: "{0} results are available",
  selectionMessage: "{0} items selected",
  emptySelectionMessage: "No selected item",
  emptySearchMessage: "No results found",
  emptyMessage: "No available options",
  aria: {
    trueLabel: "True",
    falseLabel: "False",
    nullLabel: "Not Selected",
    star: "1 star",
    stars: "{star} stars",
    selectAll: "All items selected",
    unselectAll: "All items unselected",
    close: "Close",
    previous: "Previous",
    next: "Next",
    navigation: "Navigation",
  },
};

export const deutschLocale = {
  startsWith: "Beginnt mit",
  contains: "Enthält",
  notContains: "Enthält nicht",
  endsWith: "Endet mit",
  equals: "Gleich",
  notEquals: "Nicht gleich",
  noFilter: "Kein Filter",
  lt: "Kleiner als",
  lte: "Kleiner oder gleich",
  gt: "Größer als",
  gte: "Größer als oder gleich",
  dateIs: "Datum ist",
  dateIsNot: "Datum ist nicht",
  dateBefore: "Datum liegt vor",
  dateAfter: "Datum ist nach",
  clear: "Löschen",
  apply: "Anwenden",
  matchAll: "Alle übereinstimmen",
  matchAny: "Passt zu jedem",
  addRule: "Regel hinzufügen",
  removeRule: "Regel entfernen",
  accept: "Ja",
  reject: "Nein",
  choose: "Wählen",
  upload: "Hochladen",
  cancel: "Abbrechen",
  completed: "Abgeschlossen",
  pending: "Ausstehend",
  dayNames: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ],
  dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
  dayNamesMin: ["So", "Mo", "Di", "Wir", "Do", "Fr", "Sa"],
  monthNames: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "Mär",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dez",
  ],
  chooseYear: "Jahr wählen",
  chooseMonth: "Monat wählen",
  chooseDate: "Datum auswählen",
  prevDecade: "Vorheriges Jahrzehnt",
  nextDecade: "Nächstes Jahrzehnt",
  prevYear: "Vorheriges Jahr",
  nextYear: "Nächstes Jahr",
  prevMonth: "Vorheriger Monat",
  nextMonth: "Nächster Monat",
  prevHour: "Vorherige Stunde",
  nextHour: "Nächste Stunde",
  prevMinute: "Vorherige Minute",
  nextMinute: "Nächste Minute",
  prevSecond: "Vorherige Sekunde",
  nextSecond: "Nächste Sekunde",
  am: "am",
  pm: "Uhr",
  today: "Heute",
  weekHeader: "Woche",
  firstDayOfWeek: 0,
  dateFormat: "TT/mm/JJ",
  weak: "Schwach",
  medium: "Mittel",
  strong: "Stark",
  passwordPrompt: "Gib ein Passwort ein",
  emptyFilterMessage: "Keine Ergebnisse gefunden",
  searchMessage: "{0} Ergebnisse sind verfügbar",
  selectionMessage: "{0} ausgewählte Elemente",
  emptySelectionMessage: "Kein ausgewähltes Element",
  emptySearchMessage: "Keine Ergebnisse gefunden",
  emptyMessage: "Keine verfügbaren Optionen",
  aria: {
    trueLabel: "Wahr",
    falseLabel: "Falsch",
    nullLabel: "Nicht ausgewählt",
    star: "1 Stern",
    stars: "{Stern} Sterne",
    selectAll: "Alle Elemente ausgewählt",
    unselectAll: "Alle Elemente nicht ausgewählt",
    close: "Schließen",
    previous: "Zurück",
    next: "Weiter",
    navigation: "Navigation",
  },
};

export const frenchLocale = {
  startsWith: "Commence par",
  contains: "Contient",
  notContains: "Ne contient pas",
  endsWith: "Se termine par",
  equals: "Égal",
  notEquals: "Pas égal",
  noFilter: "Pas de filtre",
  lt: "Moins que",
  lte: "Inférieur ou égal à",
  gt: "Supérieur à",
  gte: "Supérieur ou égal à",
  dateIs: "La date est",
  dateIsNot: "La date n'est pas",
  dateBefore: "La date est antérieure",
  dateAfter: "La date est après",
  clear: "Effacer",
  apply: "Appliquer",
  matchAll: "Match tout",
  matchAny: "Match Any",
  addRule: "Ajouter une règle",
  removeRule: "Supprimer la règle",
  accept: "Oui",
  reject: "Non",
  choose: "Choisir",
  upload: "Télécharger",
  cancel: "Annuler",
  completed: "Terminé",
  pending: "En attente",
  dayNames: [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    " Vendredi",
    "Samedi",
  ],
  dayNamesShort: ["Dim", "Lun", "Mar", " Mer", " Jeu", " Ven", "Sam"],
  dayNamesMin: ["Di", "Lu", "Ma", "Me", " Je", "Ve", "Sa"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "Mar",
    "Avr",
    " Mai",
    " Juin",
    " Juil",
    " oû",
    " Sep",
    " Oct",
    " Nov",
    "Déc",
  ],
  chooseYear: "Choisir l'année",
  chooseMonth: "Choisir le mois",
  chooseDate: "Choisir la date",
  prevDecade: "Décennie précédente",
  nextDecade: "La prochaine décennie",
  prevYear: "Année précédente",
  nextYear: "L'année prochaine",
  prevMonth: "Mois précédent",
  nextMonth: "Le mois prochain",
  prevHour: "Heure précédente",
  nextHour: "Heure suivante",
  prevMinute: "Minute précédente",
  nextMinute: "Minute suivante",
  prevSecond: "Seconde précédente",
  nextSecond: "Seconde suivante",
  am: "am",
  pm: "pm",
  today: "Aujourd'hui",
  weekHeader: "Wk",
  firstDayOfWeek: 0,
  dateFormat: "mm / jj / aa",
  weak: "Faible",
  medium: "Moyen",
  strong: "Fort",
  passwordPrompt: "Entrez un mot de passe",
  emptyFilterMessage: "Aucun résultat trouvé",
  searchMessage: "{0} résultats sont disponibles",
  selectionMessage: "{0} éléments sélectionnés",
  emptySelectionMessage: "Aucun élément sélectionné",
  emptySearchMessage: "Aucun résultat trouvé",
  emptyMessage: " Aucune option disponible",
  aria: {
    trueLabel: "Vrai",
    falseLabel: "Faux",
    nullLabel: " Non sélectionné",
    star: " 1 étoile",
    stars: "{étoiles} étoiles",
    selectAll: "Tous les éléments sélectionnés",
    unselectAll: "Tous les éléments non sélectionnés",
    close: "Fermer",
    previous: "Précédent",
    next: "Suivant",
    navigation: "Navigation",
  },
};

export const italianLocale = {
  startsWith: "Inizia con",
  contains: "Contiene",
  notContains: "Non contiene",
  endsWith: "Termina con",
  equals: "Uguale",
  notEquals: "Non uguale",
  noFilter: " Nessun filtro",
  lt: "Meno di",
  lte: "Minore o uguale a",
  gt: "Maggiore di",
  gte: "Maggiore o uguale a",
  dateIs: "La data è",
  dateIsNot: "La data non è",
  dateBefore: "La data è prima",
  dateAfter: "La data è dopo",
  clear: "Chiaro",
  apply: "Applicare",
  matchAll: "Abbina tutto",
  matchAny: "Abbina qualsiasi",
  addRule: "Aggiungi regola",
  removeRule: "Rimuovi regola",
  accept: "Sì",
  reject: "No",
  choose: "Scegli",
  upload: "Carica",
  cancel: "Annulla",
  completed: "Completato",
  pending: "In sospeso",
  dayNames: [
    "Domenica",
    "Lunedi",
    "Martedi",
    "Mercoledi",
    "Giovedi",
    "Venerdi",
    "Sabato",
  ],
  dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
  dayNamesMin: ["Do", "Lu", " Ma", " Me", " Gi", " Ve", "Sa"],
  monthNames: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ],
  monthNamesShort: [
    "Gen",
    "Feb",
    "Mar",
    "Apr",
    "Mag",
    "Giu",
    "Lug",
    "Ago",
    "Set",
    "Ott",
    "Nov",
    "Dic",
  ],
  chooseYear: "Scegli l'anno",
  chooseMonth: "Scegli mese",
  chooseDate: "Scegli la data",
  prevDecade: "Decennio precedente",
  nextDecade: "Il prossimo decennio",
  prevYear: "Anno precedente",
  nextYear: "L'anno prossimo",
  prevMonth: "Mese precedente",
  nextMonth: "Il mese prossimo",
  prevHour: "L'ora precedente",
  nextHour: "La prossima ora",
  prevMinute: "Minuto precedente",
  nextMinute: "Il prossimo minuto",
  prevSecond: "Precedente secondo",
  nextSecond: "Secondo successivo",
  am: "am",
  pm: "pm",
  today: "Oggi",
  weekHeader: "Wk",
  firstDayOfWeek: 0,
  dateFormat: "mm/gg/aa",
  weak: "Debole",
  medium: "Medio",
  strong: "Forte",
  passwordPrompt: "Inserisci una password",
  emptyFilterMessage: " Nessun risultato trovato",
  searchMessage: "{0} i risultati sono disponibili",
  selectionMessage: "{0} elementi selezionati",
  emptySelectionMessage: " Nessun elemento selezionato",
  emptySearchMessage: " Nessun risultato trovato",
  emptyMessage: "Nessuna opzione disponibile",
  aria: {
    trueLabel: "Vero",
    falseLabel: "Falso",
    nullLabel: "Non selezionato",
    star: " 1 stella",
    stars: "{stella} stelle",
    selectAll: "Tutti gli elementi selezionati",
    unselectAll: "Tutti gli elementi non selezionati",
    close: "Chiudi",
    previous: "Precedente",
    next: "Avanti",
    navigation: "Navigazione",
  },
};

export const loginLocale = {
  DE: {
    login: "Login",
    password: "Passwort",
    twoFactorAuth: "Zwei-Faktor-Authentifizierung",
    scanForAuthorize:
      "Um sich zu autorisieren, müssen Sie diesen QR-Code mit Ihrer Google Authentifizierungs-App scannen und den Authentifizierungscode unten eingeben",
    dontHaveAccount:
      "Wenn Sie Google Authenticator nicht haben, können Sie es von der Website",
    enterAuthCode:
      "Bitte geben Sie Ihren Authentifizierungscode von Google Authenticator ein",
    verify: "Überprüfen Sie",
    errors: {
      INVALID_CREDENTIALS: {
        summary: "Unbefugte",
        detail: "Ungültiger Benutzername oder Passwort!",
      },
      INVALID_VERIFICATION_CODE: {
        summary: "Ungültiger Code",
        detail: "Ungültiger Authentifizierungscode!",
      },
    },
  },
  EN: {
    login: "Login",
    password: "Password",
    twoFactorAuth: "Two-factor authentication",
    scanForAuthorize:
      "To be able to authorize you need to scan this QR code with your Google Authentication App and enter authentication code below",
    dontHaveAccount:
      "If you don't have Google Authenticator, you can download it from the",
    enterAuthCode:
      "Please, enter your authentication code from Google Authenticator",
    verify: "Verify",
    errors: {
      INVALID_CREDENTIALS: {
        summary: "Unauthorized",
        detail: "Invalid username or password!",
      },
      INVALID_VERIFICATION_CODE: {
        summary: "Invalid code",
        detail: "Invalid authentication code!",
      },
    },
  },
  CZ: {
    login: "Přihlásit se",
    password: "Heslo",
    twoFactorAuth: "Dvoufaktorové ověřování",
    scanForAuthorize:
      "Abyste se mohli autorizovat, musíte pomocí aplikace Google Authentication App naskenovat tento QR kód a zadat níže uvedený ověřovací kód.",
    dontHaveAccount:
      "Pokud nemáte aplikaci Google Authenticator, můžete si ji stáhnout ze stránky",
    enterAuthCode: "Zadejte svůj ověřovací kód ze služby Google Authenticator.",
    verify: "Ověřit",
    errors: {
      INVALID_CREDENTIALS: {
        summary: "Neoprávněné",
        detail: "Nesprávné uživatelské jméno nebo heslo!",
      },
      INVALID_VERIFICATION_CODE: {
        summary: "Neplatný kód",
        detail: "Neplatný ověřovací kód!",
      },
    },
  },
};
