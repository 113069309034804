export default () => {
    return {
        files: [],
        defaultFileIcon: 'mdi mdi-file-outline',
        fileIcons: {
            'xls': 'mdi mdi-file-excel-outline',
            'xlsx': 'mdi mdi-file-excel',
            'doc': 'mdi mdi-file-word-outline',
            'docx': 'mdi mdi-file-word',
            'ppt': 'mdi mdi-file-powerpoint-outline',
            'pptx': 'mdi mdi-file-powerpoint',
            'pdf': 'mdi mdi-file-chart-outline',
            'html': 'mdi mdi-file-code-outline',
            'jpg': 'mdi mdi-file-image-outline',
        },
        selectedOrg: localStorage.getItem('orgId') ? parseInt(localStorage.getItem('orgId')) : 1,
        sorting: 'asc',
        viewMode: localStorage.getItem('attachViewMode') ?? 'grid',
        isEditable: false,
        hovered: false,
    }
}
