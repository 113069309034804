import {mapGetters} from 'vuex';

export const localeMixin = {
    computed: {
        ...mapGetters({
            _t: 'locale/trans',
            _languages: 'locale/getLanguages',
        }),
    },
}
