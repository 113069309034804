import * as api from "@/api/consequentials";
import {getList} from '@/api/list'
import {getAlarmPlan,} from '@/api/alarmPlan'

const defaultIcon = {
    color: '#989898',
    name: 'mdi mdi-clock-alert-outline'
}

const callAvailableTriggers = ({state, commit}) => {
    return api.getAvailableTriggers(state.trigger.initiatorId)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    commit('setAvailableTriggers', data);
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            },
            error => {
                console.log(error);
                return Promise.reject(error);
            }
        )
}

const callList = ({state, commit}) => {
    return api.getList(state.trigger.initiatorId)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    data = data.map(i => {
                        try {
                            if (i.icon) {
                                i.icon = JSON.parse(i.icon)
                            } else {
                                i.icon = defaultIcon
                            }
                        } catch (e) {
                            i.icon = defaultIcon
                        }
                        return i;
                    })
                    commit('setTriggers', data);
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            },
            error => {
                console.log(error);
                return Promise.reject(error);
            }
        )
}

const callAlias = ({commit}, triggerType) => {
    return api.getAlias(triggerType)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    commit('setAlias', data);
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            },
            error => {
                console.log(error);
                return Promise.reject(error);
            }
        )
}

const callItem = ({commit}, id) => {
    return api.getItem(id)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    try {
                        if (data.icon) {
                            data.icon = JSON.parse(data.icon)
                        } else {
                            data.icon = defaultIcon
                        }
                    } catch (e) {
                        data.icon = defaultIcon
                    }
                    commit('setTrigger', data);
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            },
            error => {
                console.log(error);
                return Promise.reject(error);
            }
        )
}

const callRemove = ({state, commit}, id) => {
    commit('setTriggers', state.triggers.filter((element) => {
        return element.id !== id
    }));
    return api.deleteItem(id)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            },
            error => {
                console.log(error);
                return Promise.reject(error);
            }
        )
}

const callSave = ({state, commit}) => {
    if (state.trigger) {
        if (state.trigger.id) { // update
            const triggerData = {...state.trigger, icon: state.trigger.icon ? JSON.stringify(state.trigger.icon) : null}
            return api.updateItem(triggerData)
                .then(
                    ({data, status}) => {
                        if (status === 200) {
                            commit('setMode', 'update');
                            try {
                                if (data.icon) {
                                    data.icon = JSON.parse(data.icon)
                                } else {
                                    data.icon = defaultIcon
                                }
                            } catch (e) {
                                data.icon = defaultIcon
                            }
                            commit('setTrigger', data);
                            return Promise.resolve(data);
                        }
                        return Promise.reject(false);
                    },
                    error => {
                        console.log(error);
                        return Promise.reject(error);
                    }
                )
        } else { // create
            const triggerData = {...state.trigger, icon: state.trigger.icon ? JSON.stringify(state.trigger.icon) : null}
            return api.createItem(triggerData)
                .then(
                    ({data, status}) => {
                        if (status === 200) {
                            commit('setMode', 'update');
                            try {
                                if (data.icon) {
                                    data.icon = JSON.parse(data.icon)
                                } else {
                                    data.icon = defaultIcon
                                }
                            } catch (e) {
                                data.icon = defaultIcon
                            }
                            commit('setTrigger', data);
                            return Promise.resolve(data);
                        }
                        return Promise.reject(false);
                    },
                    error => {
                        console.log(error);
                        return Promise.reject(error);
                    }
                )
        }
    }
}

const callAlarmPlans = ({commit}, filter = '') => {
    return getList('ALARMPLAN', 0, filter, 1000)
        .then(({status, data}) => {
                if (status === 200) {
                    commit('setAlarmPlans', data);
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            },
            error => {
                console.log(error);
                return Promise.reject(error);
            })
}

const callAlarmPlan = ({state, commit}) => {
    if (state.trigger && state.trigger.alarmPlanId) {
        return getAlarmPlan(state.trigger.alarmPlanId)
            .then(({status, data}) => {
                    if (status === 200) {
                        commit('setAlarmPlan', data);
                        return Promise.resolve(data);
                    }
                    return Promise.reject(false);
                },
                error => {
                    console.log(error);
                    return Promise.reject(error);
                })
    }
    return Promise.resolve(null)
}

export default {
    callAvailableTriggers,
    callList,
    callAlias,
    callItem,
    callRemove,
    callSave,
    callAlarmPlans,
    callAlarmPlan,
}
