import request from "@/api/request";

export function getList(
    type, currentPage = 0, search = '', pageSize = 10, orgId = -1,
    recursive = true, sortField = 'name', showDeleted = false, alarmPointType = undefined
) {
    if (orgId === -1) {
        orgId = localStorage.getItem('orgId')
    }
    return request({
        url: `/list`,
        method: 'post',
        data: {
            currentPage: currentPage,
            filterValue: search,
            pageSize: pageSize,
            parentOrgId: orgId,
            recursive: recursive,
            type: type,
            sortField: sortField,
            sortOrder: "ASCENDING",
            deletedAlarm: showDeleted,
            alarmPointType,
        }
    })
}

export function getListExtended(
    filter, type, currentPage = 0, search = '', pageSize = 10, orgId = -1,
    recursive = true, sortField = 'name', showDeleted = false
) {
    if (orgId === -1) {
        orgId = localStorage.getItem('orgId')
    }

    filter.currentPage = currentPage
    filter.filterValue = search
    filter.pageSize = pageSize
    filter.parentOrgId = orgId
    filter.recursive = recursive
    filter.type = type
    filter.sortField = sortField
    filter.sortOrder = "ASCENDING"
    filter.deletedAlarm = showDeleted

    return request({
        url: `/list/multi`,
        method: 'post',
        data: filter
    })
}
