import request from "@/api/request";

export function getParticipant(id) {
    return request({
        url: `/participants/${id}`,
        method: 'get'
    })
}

export function getParticipantCalendar(id) {
    return request({
        url: `/participants/${id}/calendar`,
        method: 'get'
    })
}

export function createUpdateEvent(participantId, data) {
    return request({
        url: `/participants/${participantId}/calendar/update`,
        method: 'put',
        data,
    })
}

export function deleteEvent(participantId, eventId) {
    return request({
        url: `/participants/${participantId}/calendar/${eventId}`,
        method: 'delete',
    })
}

export function updateParticipant(data) {
    return request({
        url: `/participants`,
        method: 'put',
        data: data
    })
}

export function createParticipant(data) {
    return request({
        url: `/participants`,
        method: 'post',
        data: data
    })
}

export function deleteParticipant(id) {
    return request({
        url: `/participants/${id}`,
        method: 'delete',
    })
}

export function deviceList() {
    return request({
        url: `/list/devices`
    })
}

export function getParticipantGroups(id, search = '') {
    const data = {
        type: 'PARTICIPANT',
        objectId: id,
        referenceType: 'PARTICIPANT_GROUP',
        currentPage: 0,
        filterValue: search,
        pageSize: 10000
    }

    return request({
        url: `/list/membership`,
        method: 'post',
        data: data
    })
}

export function getParticipantAlarmPlans(id, search = '') {
    const data = {
        type: 'PARTICIPANT',
        objectId: id,
        referenceType: 'ALARMPLAN',
        currentPage: 0,
        filterValue: search,
        pageSize: 10000
    }

    return request({
        url: `/list/membership`,
        method: 'post',
        data: data
    })
}

export function getParticipantByLogin(login) {
    login = encodeURI(login)
    return request({
        url: `/participants/find-by-login/${login}`,
        method: 'get'
    })
}

export function cloneParticipant(data) {
    return request({
        url: `/participants/clone`,
        method: 'post',
        data,
    })
}

export function mergeParticipantToUser(data) {
    return request({
        url: `/participants/merge-to-inexistent-user`,
        method: 'put',
        data: data
    })
}
