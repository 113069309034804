import * as _ from 'lodash';
import { getList } from '@/api/list';
import * as moment from "moment-timezone";
import { getParticipantCalendar, createUpdateEvent, deleteEvent } from '@/api/participant';

const getParticipants = (context, orgId) => {
  context.commit('setLoading', true);
  getList('PARTICIPANT', context.state.participants.currentPage, context.state.search, context.state.participants.pageSize, orgId)
    .then(({ data, status }) => {
      if (status === 200) {
        data.list = data.list.map(item => ({ ...item, fullName: `${item.name} ${item.surname}` }))
          .sort((a,b) => a.fullName.localeCompare(b.fullName));
        context.commit('setParticipants', data);
      } else {
        console.log('Something wrong with items');
      }
      context.commit('setLoading', false);
    });
}

const searchParticipants = _.debounce((context, search) => {
  console.log(search);
  context.commit('setSearch', search);
  context.dispatch('getParticipants');
}, 500)

const setPage = (context, page) => {
  console.log('==> ', page);
  context.commit('setPage', page);
  context.dispatch('getParticipants');
}

const toggleParticipant = (context, ids) => {
  const deletedItems = context.state.selectedParticipants.filter(i => i && !ids.includes(i.id));
  context.state.selectedParticipants = context.state.selectedParticipants.filter(i => i && !deletedItems.map(i => i.id).includes(i.id));
  ids.map(id => context.state.selectedParticipants.push(
    context.state.participants.list.find(i => i.id === id)
  ));
}

const clearSelected = context => {
  context.commit('clearSelected');
}

const callCalendar = (context, participantId) => {
  context.commit('setCalendar', null);
  context.commit('setEvents', []);
  if (participantId) {
    return getParticipantCalendar(participantId).then(({ data, status }) => {
      if (status === 200) {
        const { events, ...calendar } = data;
        context.commit('setCalendar', calendar);
        context.commit('setEvents', events);
        return true;
      } else {
        console.log('Something wrong with individual calendar');
        return false;
      }
    });
  }
}

const setSelectedParticipants = (context, arr) => {
  context.commit('setSelectedParticipants', arr)
}

const callCreateUpdateEvent = (context, participantId) => {
  let event = context.state.event;
  event.title = event.title ?? 'Event title';
  event.endDate = event.endDate ? moment(event.endDate, 'YYYY-MM-DD').startOf('days').format('YYYY-MM-DD HH:mm:ss') : event.endDate;
  event.endDateSelected = event.endDateSelected ? moment(event.endDateSelected, 'DD.MM.YYYY').startOf('days').format('YYYY-MM-DD HH:mm:ss'): event.endDateSelected;
  event.endTime = moment(event.endTime, 'HH:mm').format('HH:mm:ss');
  event.startTime = moment(event.startTime, 'HH:mm').format('HH:mm:ss');
  event.startDateSelected = moment(event.startDateSelected, 'DD.MM.YYYY').startOf('days').format('YYYY-MM-DD HH:mm:ss');
  return createUpdateEvent(participantId, event).then(({ data, status }) => {
    if (status === 200) {
      const { events, ...calendar } = data;
      context.commit('setCalendar', calendar);
      context.commit('setEvents', events);
      context.commit('setIsEditable', false);
      return true;
    } else {
      console.log('Something wrong with individual calendar');
      return false;
    }
  });
}

const callRemoveEvent = (context, { participantId, sourceEventId  }) => {
  return deleteEvent(participantId, sourceEventId).then(({ data, status }) => {
    if (status === 200) {
      const { events, ...calendar } = data;
      context.commit('setCalendar', calendar);
      context.commit('setEvents', events);
      context.commit('setIsEditable', false);
      return true;
    } else {
      console.log('Something wrong with individual calendar');
      return false;
    }
  });
}

export default {
  getParticipants,
  searchParticipants,
  setPage,
  toggleParticipant,
  clearSelected,
  callCalendar,
  callCreateUpdateEvent,
  callRemoveEvent,
  setSelectedParticipants,
}
