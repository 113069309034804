<template>
  <div id="layout-config" :class="containerClass">
    <a v-if="isAuthenticated" id="layout-config-button" class="layout-config-button" href="#" @click="toggleConfigurator">
      <i class="pi pi-cog"></i>
    </a>
    <a class="layout-config-close" href="#" @click="hideConfigurator">
      <i class="pi pi-times"></i>
    </a>

    <div class="layout-config-content">

      <!--			<h5 style="margin-top: 0">Input Style</h5>-->
      <!--			<div class="p-formgroup-inline">-->
      <!--				<div class="p-field-radiobutton">-->
      <!--					<RadioButton id="input_outlined" name="inputstyle" value="outlined" :modelValue="inputStyle" @update:modelValue="changeInputStyle" />-->
      <!--					<label for="input_outlined">Outlined</label>-->
      <!--				</div>-->
      <!--				<div class="p-field-radiobutton">-->
      <!--					<RadioButton id="input_filled" name="inputstyle" value="filled" :modelValue="inputStyle" @update:modelValue="changeInputStyle" />-->
      <!--					<label for="input_filled">Filled</label>-->
      <!--				</div>-->
      <!--			</div>-->

      <!--			<h5>Ripple Effect</h5>-->
      <!--			<InputSwitch :modelValue="rippleActive" @update:modelValue="changeRipple" />-->

      <h5>{{ _t('label_menu_type') }}</h5>
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <RadioButton id="static" v-model="d_layoutMode" name="layoutMode" value="static"
                       @change="changeLayout($event, 'static')"/>
          <label for="static">{{ _t('label_static') }}</label>
        </div>
        <div class="p-field-radiobutton">
          <RadioButton id="overlay" v-model="d_layoutMode" name="layoutMode" value="overlay"
                       @change="changeLayout($event, 'overlay')"/>
          <label for="overlay">Overlay</label>
        </div>
      </div>

      <h5>{{ _t('label_menu_colour') }}</h5>
      <div class="p-formgroup-inline">
        <div class="p-field-radiobutton">
          <RadioButton id="dark" v-model="d_layoutColorMode" name="layoutColorMode" value="dark"
                       @change="changeLayoutColor($event, 'dark')"/>
          <label for="dark">{{ _t('label_dark') }}</label>
        </div>
        <div class="p-field-radiobutton">
          <RadioButton id="light" v-model="d_layoutColorMode" name="layoutColorMode" value="light"
                       @change="changeLayoutColor($event, 'light')"/>
          <label for="light">{{ _t('label_light') }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    layoutMode: {
      type: String,
      default: null
    },
    layoutColorMode: {
      type: String,
      default: null
    },
    isAuthenticated: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false,
      d_layoutMode: this.layoutMode,
      d_layoutColorMode: this.layoutColorMode,
    }
  },
  watch: {
    $route() {
      if (this.active) {
        this.active = false;
        this.unbindOutsideClickListener();
      }
    },
    layoutMode(newValue) {
      this.d_layoutMode = newValue;
    },
    layoutColorMode(newValue) {
      this.d_layoutColorMode = newValue;
    }
  },
  outsideClickListener: null,
  methods: {
    toggleConfigurator(event) {
      this.active = !this.active;
      event.preventDefault();

      if (this.active)
        this.bindOutsideClickListener();
      else
        this.unbindOutsideClickListener();
    },
    hideConfigurator(event) {
      this.active = false;
      this.unbindOutsideClickListener();
      event.preventDefault();
    },
    changeInputStyle(value) {
      this.$appState.inputStyle = value;
    },
    changeRipple(value) {
      this.$primevue.config.ripple = value;
    },
    changeLayout(event, layoutMode) {
      this.$emit('layout-change', layoutMode);
      event.preventDefault();
    },
    changeLayoutColor(event, layoutColor) {
      this.$emit('layout-color-change', layoutColor);
      event.preventDefault();
    },
    bindOutsideClickListener() {
      if (!this.outsideClickListener) {
        this.outsideClickListener = (event) => {
          if (this.active && this.isOutsideClicked(event)) {
            this.active = false;
          }
        };
        document.addEventListener('click', this.outsideClickListener);
      }
    },
    unbindOutsideClickListener() {
      if (this.outsideClickListener) {
        document.removeEventListener('click', this.outsideClickListener);
        this.outsideClickListener = null;
      }
    },
    isOutsideClicked(event) {
      return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
    }
  },
  computed: {
    containerClass() {
      return ['layout-config', {'layout-config-active': this.active}];
    },
    rippleActive() {
      return this.$primevue.config.ripple;
    },
    inputStyle() {
      return this.$appState.inputStyle;
    }
  }
}
</script>
