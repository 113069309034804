import {getFileIcon} from './helper';

export default {
    setFiles: function (state, data) {
        state.files = [];
        state.files = data;
    },
    setSorting: (state, order = 'asc') => {
        state.sorting = order
    },
    setOrganization(state, orgId) {
        state.selectedOrg = parseInt(orgId);
    },
    setIsEditable(state, status) {
        state.isEditable = status;
    },
    setViewMode(state, viewMode) {
        state.viewMode = viewMode;
        localStorage.setItem('attachViewMode', viewMode);
    },
    removeFile(state, file) {
        state.files.splice(state.files.findIndex(i => i.path === file.path), 1);
    },
    addFile(state, file) {
        if (!state.files.find(f => f.path === file.path)) {
            state.files.push({
                name: file.name,
                path: file.path,
                ext: file.type,
                icon: getFileIcon(state, file.type),
                selected: false,
                orgId: parseInt(state.selectedOrg),
                orgName: '',
                hovered: false,
            });
        }
    },
    moveToOrg(state, {files, orgId}) {
        const paths = files.map(f => f.path);
        state.files.filter(f => paths.includes(f.path))
            .map(f => {
                f.orgId = parseInt(orgId);
                const pathArr = f.path.split('/');
                pathArr.shift();
                f.selected = false;
                f.hovered = false;
                f.path = pathArr.splice(0, 0, orgId).join('/');
            });
    }
}
