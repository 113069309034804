export default {
    getAvailableTriggers(state) {
        if (state.trigger.id) {
            return state.availableTriggers.concat(state.trigger.consequentialTrigger);
        }
        return state.availableTriggers;
    },
    getTriggers(state) {
        return (state.availableTriggers ?? []).map(name => ({
            name,
            trigger: null,
        }))
            .concat((state.triggers ?? []).map(trigger => ({
                name: trigger.consequentialTrigger,
                trigger,
            })))
            .sort((a, b) => a.name.localeCompare(b.name));
    },
    getTrigger(state) {
        return state.trigger;
    },
    getMode(state) {
        return state.mode;
    },
    getAlarmPlan(state) {
        return state.alarmPlan;
    },
    getAlarmPlans(state) {
        return state.alarmPlans.list;
    }
}
