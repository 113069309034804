import request from "@/api/request";

export function getUser(id) {
    return request({
        url: `/users/${id}`,
        method: 'get'
    })
}

export function updateUser(data) {
    return request({
        url: `/users`,
        method: 'put',
        data: data
    })
}

export function createUser(data) {
    return request({
        url: `/users`,
        method: 'post',
        data: data
    })
}

export function deleteUser(id) {
    return request({
        url: `/users/${id}`,
        method: 'delete'
    })
}

export function getUserByLogin(login) {
    login = encodeURI(login)
    return request({
        url: `/users/find-by-login/${login}`,
        method: 'get'
    })
}

export function changePassword(oldPassword, newPassword) {
    return request({
        url: `/users/change-password`,
        method: 'patch',
        data: {
            newPassword,
            previousPassword: oldPassword,
        }
    })
}
