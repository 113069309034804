export default () => {
    return {
        templates: [],
        template: null,
        meta: {
            currentPage: 0,
            count: 0,
            pageSize: 10,
            pagesCount: 0,
        },
    }
}
