import moment from "moment-timezone";

/* CALENDARS */
const setCalendars = (state, data) => {
  if (data) {
    state.calendars = data.list;
    state.calendarMeta.count = data.count;
    state.calendarMeta.currentPage = data.currentPage;
    state.calendarMeta.pageSize = data.pageSize;
    state.calendarMeta.pagesCount = data.pagesCount;
  } else {
    state.calendars = [];
    state.calendarMeta.count = 0;
    state.calendarMeta.currentPage = 0;
    state.calendarMeta.pageSize = 20;
    state.calendarMeta.pagesCount = 0;
  }
}

const addCalendar = (state, calendar) => {
  if (calendar) {
    state.calendars.push(calendar);
    state.calendars.sort((a,b) => b.validFrom.localeCompare(a.validFrom));
  }
}

const updateCalendar = (state, data) => {
  if (data && data.id) {
    let calendar = state.calendars.find(i => i.id === data.id);
    calendar.id = data.id;
    calendar.description = data.description;
    calendar.orgId = data.orgId;
    calendar.orgName = data.orgName;
    calendar.validFrom = data.validFrom;
    calendar.version = data.version;
  }
}

const setCalendar = (state, data) => {
  if (data) {
    if (!state.calendar) {
      state.calendar = {};
    }
    Object.keys(data).map(key => {
      if (data[key] || key === 'id') {
        state.calendar[key] = data[key];
      }
    });
  } else {
    state.calendar = null;
  }
}

const createEmptyCalendar = (state, { orgId = 1, orgName = '' }) => {
  state.calendar = {
    id: null,
    name: '',
    description: '',
    orgId,
    orgName,
    validFrom: moment().startOf('days').format('YYYY-MM-DD HH:mm:ss'),
    version: 0,
  };
  state.events = [];
}

const selectCalendar = (state, calendarId) => {
  state.events = [];
  if (calendarId) {
    state.calendar = state.calendars.find(i => i.id.toString() === calendarId.toString()) ?? state.calendar;
    state.isEditable = false;
  } else {
    state.calendar = null;
  }
}

const removeCalendar = (state, calendarId) => {
  state.calendars.splice(state.calendars.findIndex(i => i.id === calendarId), 1);
  state.calendar = null;
}

/* EVENTS */
const setEvents = (state, events) => {
  state.events = events.map(g => ({
    ...g,
    participantRotationEvents: g.participantRotationEvents.map(e => ({
      ...e,
      dateRule: {
        ...e.dateRule,
        start: moment(e.dateRule.start, 'HH:mm:ss').format('HH:mm'),
        end: moment(e.dateRule.end, 'HH:mm:ss').format('HH:mm'),
      }
    }))
  }));
}

const createWeek = (state) => {
  state.events.push({
    participantRotationEvents: [],
    calendarId: null,
    id: null,
    orderId: 0,
    version: 0,
  });
}

const removeWeek = (state, weekIndex) => {
  state.events.splice(weekIndex, 1);
}

const createEvent = (state, weekIndex) => {
  state.events[weekIndex].participantRotationEvents.push({
    dateRule: {
      countAfterTimesEnd: null,
      end: "23:59",
      endDate: null,
      holiday: false,
      id: null,
      markedDays: [],
      repeatEvery: 1,
      start: "00:00",
      type: "WeeklyDateRule",
      validFrom: null,
      version: 0,
    },
    id: null,
    individualCalendarId: state.calendar.id,
    name: "",
    orderId: 0,
    version: 0,
    weekGroupId: null,
  });
}

const removeEvent = (state, { weekGroupIndex, eventIndex }) => {
  state.events[weekGroupIndex].participantRotationEvents.splice(eventIndex, 1);
}

const cloneEvent = (state, { weekGroupIndex, eventIndex }) => {
  try {
    const event = state.events[weekGroupIndex].participantRotationEvents[eventIndex];
    state.events[weekGroupIndex].participantRotationEvents.splice(eventIndex, 0, JSON.parse(JSON.stringify(event)));
  } catch(e) {
    //
  }
}

/* PARTICIPANTS */
const setParticipants = (state, data) => {
  state.participants = data.list;
  state.participantMeta.count = data.count;
  state.participantMeta.currentPage = data.currentPage;
  state.participantMeta.pageSize = data.pageSize;
  state.participantMeta.pagesCount = data.pagesCount;
}

/* ASSIGNMENT */
const setAssignment = (state, data) => {
  state.assignment = data.list;
  state.assignmentMeta.count = data.count;
  state.assignmentMeta.currentPage = data.currentPage;
  state.assignmentMeta.pageSize = data.pageSize;
  state.assignmentMeta.pagesCount = data.pagesCount;
}

/* COMMON */
const setIsEditable = (state, status) => {
  state.isEditable = status;
}

const startLoadingCalendars = (state) => {
  state.loadingCalendars = true;
}

const endLoadingCalendars = (state) => {
  state.loadingCalendars = false;
}

const startLoadingEvents = (state) => {
  state.loadingEvents = true;
}

const endLoadingEvents = (state) => {
  state.loadingEvents = false;
}

export default {
  setCalendars,
  addCalendar,
  updateCalendar,
  setCalendar,
  createEmptyCalendar,
  selectCalendar,
  removeCalendar,

  setEvents,
  createWeek,
  removeWeek,
  createEvent,
  removeEvent,
  cloneEvent,

  setParticipants,

  setAssignment,

  setIsEditable,

  startLoadingCalendars,
  endLoadingCalendars,
  startLoadingEvents,
  endLoadingEvents,
};
