import moment from 'moment-timezone';

const setHolidays = (state, data) => {
    if (data) {
        state.holidays = data.events;
    } else {
        state.holidays = [];
    }
    state.holidaysMeta.id = data.id;
    state.holidaysMeta.version = data.version;
    state.holidaysMeta.orgId = data.orgId;
    state.holidaysMeta.orgName = data.orgName;
    state.holidaysMeta.name = data.name;
    state.holidaysMeta.validFrom = data.validFrom;
    state.holidaysMeta.description = data.description;
    state.holidaysMeta.year = data.year;
}

const setHoliday = (state, data) => {
    if (data) {
        Object.keys(data).map(key => {
            if (data[key] || key === 'id') {
                state.holiday[key] = data[key];
            }
        });
    } else {
        state.holiday = null;
    }
}

const addHoliday = (state, data) => {
    if (data) {
        state.holidays.push(data);
        state.holidays.sort((a, b) => b.validFrom.localeCompare(a.validFrom));
    }
}

const updateHoliday = (state, data) => {
    if (data && data.id) {
        let holiday = state.holidays.find(i => i.id === data.id);
        holiday.id = data.id;
        holiday.title = data.title;
        holiday.active = data.active;
        holiday.allDay = data.allDay;
        holiday.start = data.start;
        holiday.end = data.end;
        holiday.recurring = data.recurring;
        holiday.standard = data.standard;
        holiday.singleDay = data.singleDay;
    }
}

const createEmptyHoliday = (state, date) => {
    state.holiday = {
        id: null,
        title: '',
        active: true,
        allDay: true,
        start: moment(date).startOf('days').toDate(),
        end: moment(date).endOf('days').toDate(),
        recurring: false,
        standard: false,
        singleDay: true,
    };
    state.isEditable = false;
}

const selectHoliday = (state, holidayId) => {
    if (holidayId) {
        state.holiday = state.holidays.find(i => i.id === holidayId) ?? state.holiday;
        state.holiday.start = moment(state.holiday.start, 'YYYY-MM-DD HH:mm').toDate();
        state.holiday.end = moment(state.holiday.end, 'YYYY-MM-DD HH:mm').toDate();
        state.holiday.singleDay = moment(state.holiday.start, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD') === moment(state.holiday.end, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
        state.isEditable = true;
    } else {
        state.holiday = null;
    }
}

const removeHoliday = (state, holidayId) => {
    state.holidays.splice(state.holidays.findIndex(i => i.id === holidayId), 1);
    state.holiday = null;
}

export default {
    setHolidays,
    setHoliday,
    addHoliday,
    updateHoliday,
    createEmptyHoliday,
    selectHoliday,
    removeHoliday,
}
