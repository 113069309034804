const getCalendar = state => state.calendar;
const getEvents = state => state.events;
const getEvent = state => state.event;
const getIsEditable = state => state.isEditable;
const getRepeatTypes = state => state.repeatTypes;

export default {
    getCalendar,
    getEvents,
    getEvent,
    getIsEditable,
    getRepeatTypes,
}
