const setLines = (state, data) => {
    try {
        data = JSON.parse(data);
    } catch (e) { /* nothing to do */
    }
    if (data) {
        state.lines = data;
    } else {
        state.lines = [];
    }
}

const addWidget = (state, {line, widget}) => {
    state.lines[line].push(widget);
}

const removeWidget = (state, {line, index}) => {
    state.lines[line].splice(index, 1);
}

const setIsEditable = (state, status = false) => {
    state.isEditable = status;
}

const setIsLoading = (state, status = false) => {
    state.isLoading = status;
}

const setGeneralInfo = (state, data) => {
    state.generalInfo = data
}

const setMediaCallsByTypes = (state, data) => {
    state.mediaCallsByTypes.items = data;
}

const setPeriodMediaCallsByTypes = (state, data) => {
    state.mediaCallsByTypes.period = data;
}

const setPeriodsMediaCallsByTypes = (state, data) => {
    state.mediaCallsByTypes.periods = data;
}

const setMediaCalls = (state, data) => {
    state.mediaCalls.items = data;
}

const setPeriodMediaCalls = (state, data) => {
    state.mediaCalls.period = data;
}

const setPeriodsMediaCalls = (state, data) => {
    state.mediaCalls.periods = data;
}

const setStatistics = (state, data) => {
    state.statistics.items = data;
}

const setPeriodStatistics = (state, data) => {
    state.statistics.period = data;
}

const setPeriodsStatistics = (state, data) => {
    state.statistics.periods = data;
}

const setResources = (state, data) => {
    state.resources = data;
}

export default {
    setLines,
    addWidget,
    removeWidget,
    setIsEditable,
    setIsLoading,
    setGeneralInfo,
    setMediaCallsByTypes,
    setPeriodMediaCallsByTypes,
    setPeriodsMediaCallsByTypes,
    setMediaCalls,
    setPeriodMediaCalls,
    setPeriodsMediaCalls,
    setStatistics,
    setPeriodStatistics,
    setPeriodsStatistics,
    setResources,
}
