import * as moment from 'moment-timezone';

const setParticipants = (state, data) => {
    state.participants.count = data.count;
    state.participants.currentPage = data.currentPage;
    state.participants.pageSize = data.pageSize;
    state.participants.pagesCount = data.pagesCount;
    state.participants.list = data.list;
}

const setLoading = (state, status) => {
    state.loading = status;
}

const setSelectedParticipants = (state, arr) => {
    state.selectedParticipants = arr
}

const setSearch = (state, search) => {
    state.search = search;
}

const setPage = (state, page) => {
    state.participants.currentPage = page;
}

const nextPage = (state) => {
    if (state.participants.currentPage + 1 <= state.participants.pagesCount) {
        state.participants.currentPage++;
    }
}

const prevPage = (state) => {
    if (state.participants.currentPage - 1 >= 0) {
        state.participants.currentPage--;
    }
}

const resetPage = (state) => {
    state.participants.currentPage = 0;
}

const clearSelected = state => {
    state.selectedParticipants = [];
}

const setCalendar = (state, data) => {
    if (data) {
        state.calendar = data;
    } else {
        state.calendar = null;
    }
}

const setEvents = (state, data) => {
    if (data) {
        state.events = data.map(event => ({
            ...event,
            endDate: event.endDate ? moment(event.endDate, 'YYYY-MM-DD HH:mm:ss').startOf('days').format('YYYY-MM-DD') : event.endDate,
            endDateSelected: moment(event.endDateSelected, 'YYYY-MM-DD HH:mm:ss').startOf('days').format('DD.MM.YYYY'),
            endTime: moment(event.endTime, 'HH:mm:ss').format('HH:mm'),
            startTime: moment(event.startTime, 'HH:mm:ss').format('HH:mm'),
            startDateSelected: moment(event.startDateSelected, 'YYYY-MM-DD HH:mm:ss').startOf('days').format('DD.MM.YYYY'),
        }));
    } else {
        state.events = [];
    }
}

const addEvent = (state, event) => {
    state.events.push(event);
}

const setEvent = (state, data) => {
    if (data) {
        state.event = data;
    } else {
        state.event = null;
    }
}

const selectEvent = (state, sourceEventId) => {
    state.event = state.events.find(i => i.sourceEventId === sourceEventId);
}

const setIsEditable = state => state.isEditable = true;

const createEmptyEvent = state => {
    state.isEditable = false;
    state.event = {
        acknowledgeTime: 1,
        active: true,
        callingNumber: null,
        countAfterTimesEnd: null,
        day: moment().format('YYYY-MM-DD'),
        deviceType: null,
        endDate: moment().format('YYYY-MM-DD'),
        endDateSelected: moment().format('DD.MM.YYYY'),
        endTime: '23:59',
        endType: "AFTER_TIMES_END",
        markedDays: null,
        monthSubType: "DAY_OF_THE_MONTH",
        recurring: false,
        repeatEvery: 1,
        repeatType: "DAILY",
        ruleId: null,
        sourceEventId: null,
        startDateSelected: moment().format('DD.MM.YYYY'),
        startTime: '00:00',
        title: null,
    }
}

export default {
    setParticipants,
    setLoading,
    setSearch,
    resetPage,
    setPage,
    nextPage,
    prevPage,
    clearSelected,
    setCalendar,
    setEvents,
    addEvent,
    setEvent,
    selectEvent,
    setIsEditable,
    createEmptyEvent,
    setSelectedParticipants,
};
