export default {
    setInitiatorId(state, id) {
        state.trigger.initiatorId = id;
    },
    setAvailableTriggers(state, data) {
        state.availableTriggers = data;
    },
    setTriggers(state, data) {
        state.triggers = data;
    },
    setAlias(state, data) {
        state.trigger.name = data;
    },
    setTriggerType(state, data) {
        state.trigger.consequentialTrigger = data;
    },
    setTrigger(state, data) {
        state.trigger = data;
    },
    setEmptyTrigger(state) {
        state.trigger = {
            ...state.trigger,
            acknowledgeMode: "NONE",
            alarmPlanId: null,
            alarmPointType: "CONSEQUENTIAL",
            confirmPriority: 1,
            countRepeat: 0,
            deleted: true,
            description: "Description",
            id: null,
            icon: {
                color: '#989898',
                name: 'mdi mdi-clock-alert-outline'
            },
            instructionFiles: [],
            localAcknowledgeTime: 1,
            mediaProcessingType: "BY_RANK",
            mediaUsageType: "ALL",
            orgId: state.orgId,
            orgName: '',
            repeatBetween: 0,
            rotationalCalendarId: null,
            speechText: '',
            text: ''
        }
    },
    setMode(state, mode = null) {
        if ([null, 'new', 'update'].includes(mode)) {
            state.mode = mode;
        }
    },
    setDefaultTrigger(state) {
        state.trigger = {
            name: '',
            acknowledgeMode: "NONE",
            alarmPlanId: null,
            alarmPointType: "CONSEQUENTIAL",
            confirmPriority: 1,
            consequentialTrigger: "ON_NEW",
            countRepeat: 0,
            deleted: true,
            description: "Description",
            id: null,
            icon: {
                color: '#989898',
                name: 'mdi mdi-clock-alert-outline'
            },
            initiatorId: null,
            instructionFiles: [],
            localAcknowledgeTime: 1,
            mediaProcessingType: "BY_RANK",
            mediaUsageType: "ALL",
            orgId: state.orgId,
            orgName: '',
            repeatBetween: 0,
            rotationalCalendarId: null,
            speechText: '',
            text: '',
        }
    },
    setOrgId(state, orgId) {
        if (state.trigger) {
            state.trigger.orgId = orgId
        }
        state.orgId = orgId
    },
    setAlarmPlan(state, data) {
        state.alarmPlan = data;
    },
    setAlarmPlans(state, data) {
        state.alarmPlans = {...data, list: data.list.sort((a, b) => a.name.localeCompare(b.name))};
    }
}
