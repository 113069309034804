export default () => {
    return {
        token: null,
        menu: [
            {key: 'menu.dashboard', label: 'menu.dashboard', icon: 'mdi mdi-view-dashboard', to: '/'},
            {
                key: 'menu.alarm_monitor',
                label: 'menu.alarm_monitor',
                icon: 'mdi mdi-monitor',
                class: 'parent',
                items: [
                    {
                        key: 'menu.trigger_alarm',
                        label: 'menu.trigger_alarm',
                        icon: 'mdi mdi-gesture-double-tap',
                        to: '/alarm-panels',
                        access: 'ALARM_PANEL'
                    },
                    {
                        key: 'menu.manual_call',
                        label: 'label_manual_call',
                        icon: 'mdi mdi-launch',
                        to: '/alarm-trigger',
                        access: 'MANUAL_CALL'
                    },
                    {
                        key: 'menu.alarm_table',
                        label: 'menu.alarm_table',
                        icon: 'mdi mdi-table-alert',
                        to: '/alarm-table?tab=0',
                        access: 'ALARM_TABLE'
                    },
                    {
                        key: 'menu.alarm_history',
                        label: 'menu.history',
                        icon: 'mdi mdi-bullhorn',
                        to: '/alarm-table?tab=2',
                        access: 'ALARM_TABLE'
                    },
                     {
                        key: 'menu.my_alarms',
                        label: 'menu.my_alarms',
                        icon: 'mdi mdi-bell-alert',
                        to: '/alarm-table?tab=1',
                        access: 'ALARM_TABLE'
                    },
                    {
                        key: 'menu.checkin_checkout',
                        label: 'menu.checkin_checkout',
                        icon: 'mdi mdi-account-plus',
                        to: '/',
                        disabled: true
                    },
                    {
                        key: 'menu.lone_worker',
                        label: 'menu.lone_worker',
                        icon: 'mdi mdi-map-marker',
                        to: '/alarm-table?tab=4',
                        disabled: true
                    },
                ]
            },
            {
                key: 'menu.alarm_definition', label: 'menu.alarm_definition', icon: 'mdi mdi-tune', items: [
                    {
                        key: 'menu.alarm_definition',
                        label: 'menu.alarm_definition.new',
                        icon: 'mdi mdi-tune',
                        to: '/alarm-points'
                    },
                    {
                        key: 'menu.deleted_alarms',
                        label: 'menu.deleted_alarm_points',
                        icon: 'mdi mdi-refresh',
                        to: '/deleted-alarm-points',
                        access: 'DELETED_ALARM_RESTORAGE'
                    },
                    {
                        key: 'menu.alarm_apps',
                        label: 'menu.alarm_apps',
                        icon: 'mdi mdi-apps',
                        to: '/alarm-apps',
                        access: 'MOBILE_APP'
                    },
                    {
                        key: 'menu.standby_schedules',
                        label: 'menu.standby_schedules',
                        icon: 'mdi mdi-account-switch',
                        to: '/standby-schedules'
                    },
                    {
                        key: 'menu.alarm_rules',
                        label: 'menu.alarm_rules',
                        icon: 'mdi mdi-language-javascript',
                        to: '/alarm-rules',
                        access: 'ALARM_EVENT_RULE'
                    },
                    {
                        key: 'menu.alarm_templates',
                        label: 'menu.alarm_templates',
                        icon: 'mdi mdi-text-box-check-outline',
                        to: '/alarm-templates'
                    },
                    {
                        key: 'menu.text_templates',
                        label: 'menu.text_templates',
                        icon: 'mdi mdi-content-save-outline ',
                        to: '/text-templates'
                    },
                    {
                        key: 'menu.attachments',
                        label: 'menu.attachments',
                        icon: 'mdi mdi-paperclip',
                        to: '/attachments',
                        access: 'INSTRUCTION_FILES'
                    },
                ]
            },
            {
                key: 'menu.master_data',
                label: 'menu.master_data',
                icon: 'mdi mdi-account-cog',
                class: 'parent',
                items: [
                    {
                        key: 'menu.participants',
                        label: 'menu.participants',
                        icon: 'mdi mdi-account',
                        class: 'parent',
                        items: [
                            {
                                key: 'menu.alarm_participants',
                                label: 'menu.alarm_participants',
                                icon: 'mdi mdi-account-voice',
                                to: '/participants'
                            },
                            {
                                key: 'menu.participant_groups',
                                label: 'menu.participant_groups',
                                icon: 'mdi mdi-account-group',
                                to: '/participant-groups'
                            },
                            {
                                key: 'menu.participant_plans',
                                label: 'menu.participant_plans',
                                icon: 'mdi mdi-phone-log',
                                to: '/alarm-plans'
                            },
                            {
                                key: 'menu.team_media_schedules',
                                label: 'menu.team_media_schedules',
                                icon: 'mdi mdi-card-account-phone',
                                to: '/team-schedules'
                            },
                            {
                                key: 'menu.private_media_schedules',
                                label: 'menu.private_media_schedules',
                                icon: 'mdi mdi-card-account-phone-outline',
                                to: '/private-schedules'
                            },
                            {key: 'menu.holidays', label: 'menu.holidays', icon: 'mdi mdi-beach', to: '/holidays'},
                        ]
                    },
                    {key: 'menu.users', label: 'menu.users', icon: 'mdi mdi-shield-account', to: '/users'},
                    {key: 'menu.roles', label: 'menu.roles', icon: 'mdi mdi-shield-lock', to: '/user-groups'},
                    {
                        key: 'menu.organizations',
                        label: 'menu.organizations',
                        icon: 'mdi mdi-factory',
                        to: '/organizations'
                    },
                ]
            },
            {
                key: 'menu.reporting', label: 'menu.reporting', icon: 'mdi mdi-poll', class: 'parent', items: [
                    {
                        key: 'menu.detailed_reports',
                        label: 'menu.detailed_reports',
                        icon: 'mdi mdi-clipboard-edit-outline',
                        to: '/reports/detailed',
                        access: 'REPORTS'
                    },
                    {
                        key: 'menu.monthly_reports',
                        label: 'menu.monthly_reports',
                        icon: 'mdi mdi-clipboard-edit',
                        to: '/reports/monthly',
                        access: 'REPORTS'
                    },
                ]
            },
            {
                key: 'menu.technical_configuration',
                label: 'menu.technical_configuration',
                icon: 'mdi mdi-cog',
                class: 'parent',
                items: [
                    {
                        key: 'menu.database_management',
                        label: 'menu.database_management',
                        icon: 'mdi mdi-server',
                        to: '/',
                        disabled: true,
                    },
                    {
                        key: 'menu.ixarma_connect',
                        label: 'menu.ixarma_connect',
                        icon: 'mdi mdi-leak',
                        to: '/',
                        disabled: true,
                    },
                    {
                        key: 'menu.media_modules',
                        label: 'menu.media_modules',
                        icon: 'mdi mdi-apps',
                        to: '/',
                        disabled: true,
                    },
                    {
                        key: 'menu.import_export',
                        label: 'menu.import_export',
                        icon: 'mdi mdi-swap-vertical',
                        to: '/import-export',
                        disabled: true,
                    },
                    {
                        key: 'menu.system_info',
                        label: 'menu.system_info',
                        icon: 'mdi mdi-shield-alert',
                        to: '/',
                        disabled: true,
                    },
                    {
                        key: 'menu.system_tables',
                        label: 'menu_system_tables',
                        icon: 'pi pi-table',
                        to: '/views',
                        disabled: localStorage.getItem("GCOP") !== 'true',
                    },
                ]
            },
        ],
    }
}
