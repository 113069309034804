import request from "@/api/request";
import moment from "moment";

export function getAll(
    currentPage = 0,
    field = '',
    filterText = null,
    perPage,
    columnsFilter = null,
    organizationFilter = [],
    duration = null,
    orderByPriority = false,
    participantsFilter = null
) {
    return request({
        url: `/alarmtable`,
        method: 'post',
        data: {
            field,
            currentPage: currentPage,
            duration: mapDuration(duration),
            orderByPriority: orderByPriority,
            pageSize: perPage,
            sortField: field,
            sortOrder: "ASCENDING",
            textFilter: filterText,
            columnsFilter: columnsFilter,
            organizationFilter: organizationFilter,
            participantsFilter: participantsFilter,
        }
    })
}

export function getAlarm(jobId) {
    return request({
        url: `/alarmtable/details/${jobId}`,
        method: 'get',
    })
}

export function terminateAlarm(alarmId) {
    return request({
        url: `/alarmpoints/quit/${alarmId}`,
        method: 'get',
    })
}

export function prepareTriggerAgainAlarm(alarmJobId, type = 'alarmtable') {
    return request({
        url: `/${type}/prepare-trigger-again/${alarmJobId}`,
        method: 'get',
    })
}

export function triggerAgainAlarm(data) {
    return request({
        url: `/alarm-trigger`,
        method: 'post',
        data: data

    })
}

export function getFileLink(
    type,
    currentPage = 0,
    field = '',
    filterText = null,
    perPage,
    columnsFilter = null,
    organizationFilter = [],
    duration = null,
    orderByPriority = false,
    participantsFilter = null
) {

    return request({
        url: `${type}`,
        method: 'post',
        responseType: 'arraybuffer',
        data: {
            currentPage: currentPage,
            duration: mapDuration(duration),
            pageSize: perPage,
            orderByPriority: orderByPriority,
            sortField: field,
            sortOrder: "ASCENDING",
            textFilter: filterText,
            columnsFilter: columnsFilter,
            organizationFilter: organizationFilter,
            participantsFilter: participantsFilter,
        }
    })
}

export function getHistory(
    currentPage = 0,
    sortField = '',
    textFilter = null,
    pageSize,
    columnsFilter = null,
    organizationFilter = null,
    duration = null,
    orderByPriority = false,
    participantsFilter = null
) {
    return request({
        url: `/history`,
        method: 'post',
        data: {
            currentPage: currentPage,
            duration: mapDuration(duration),
            pageSize,
            sortField: sortField ?? 'alarmJobStartDate',
            sortOrder: "ASCENDING",
            textFilter,
            columnsFilter,
            organizationFilter,
            participantsFilter: participantsFilter,
            orderByPriority: orderByPriority,
        }
    })
}

export function getHistoryByAlarm(
    alarmId,
    currentPage = 0,
    sortField = '',
    textFilter = null,
    pageSize,
    columnsFilter = null,
    organizationFilter = null,
    duration = null,
    participantsFilter = null
) {
    return request({
        url: `/history/by-alarm?alarmId=${alarmId}`,
        method: 'post',
        data: {
            currentPage: currentPage,
            duration: mapDuration(duration),
            pageSize,
            sortField: 'alarmJobStartDate' ?? sortField,
            sortOrder: "ASCENDING",
            textFilter,
            columnsFilter,
            organizationFilter,
            participantsFilter: participantsFilter,
        }
    })
}

export function getHistoryDetails(historyId) {
    return request({
        url: `/history/details/${historyId}`,
        method: 'get',
    })
}

export function getChild(id) {
    return request({
        url: `/organizations/${id}/children`,
        method: 'get',
    })
}

export function mapDuration(duration) {
    const customDuration = {
        ...duration
    }

    if (customDuration.start !== null) {
        customDuration.start = {
            "dayOfMonth": moment(customDuration.start).format('DD').toString(),
            "month": moment(customDuration.start).format('MMMM').toString().toUpperCase(),
            "year": moment(customDuration.start).format('YYYY').toString()
        }
    }

    if (customDuration.end !== null) {
        customDuration.end = {
            "dayOfMonth": moment(customDuration.end).format('DD').toString(),
            "month": moment(customDuration.end).format('MMMM').toString().toUpperCase(),
            "year": moment(customDuration.end).format('YYYY').toString()
        }

    }

    return customDuration
}
