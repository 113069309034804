import {createUpdateEvent, deleteEvent, getAlarmPointCalendar} from '@/api/alarmPoint';
import * as moment from "moment-timezone";

const callCalendar = (context, alarmpointId) => {
    context.commit('setCalendar', null);
    context.commit('setEvents', []);
    if (alarmpointId) {
        return getAlarmPointCalendar(alarmpointId).then(({data, status}) => {
            if (status === 200) {
                const {events, ...calendar} = data;
                context.commit('setCalendar', calendar);
                context.commit('setEvents', events);
                return true;
            } else {
                console.log('Something wrong with individual calendar');
                return false;
            }
        });
    }
}

const callCreateUpdateEvent = (context, {alarmPointId, alarmPoint = null}) => {
    let event = context.state.event;
    if (alarmPoint) {
        event.alarmReplacement = {
            // acknowledgeMode: alarmPoint.acknowledgeMode,
            // active: alarmPoint.active,
            alarmPointId: alarmPoint.alarmPointId,
            // alarmPointType: alarmPoint.alarmPointType,
            // confirmPriority: alarmPoint.confirmPriority,
            // countRepeat: alarmPoint.countRepeat,
            // deleted: alarmPoint.deleted,
            // description: alarmPoint.description,
            // icon: alarmPoint.icon,
            id: alarmPoint.id,
            // instructionFile: alarmPoint.instructionFile,
            // localAcknowledgeTime: alarmPoint.localAcknowledgeTime,
            // mediaProcessingType: alarmPoint.mediaProcessingType,
            // mediaUsageType: alarmPoint.mediaUsageType,
            // name: alarmPoint.name,
            // orgId: alarmPoint.orgId,
            // orgName: alarmPoint.orgName,
            // repeatBetween: alarmPoint.repeatBetween,
            // repeatMonInterval: alarmPoint.repeatMonInterval,
            // speechText: alarmPoint.speechText,
            // systemId: alarmPoint.systemId,
            // text: alarmPoint.text,
            // triggering: alarmPoint.triggering,
        };
    }
    event.title = event.title ?? 'Event title';
    event.endDate = event.endDate ? moment(event.endDate, 'YYYY-MM-DD').startOf('days').format('YYYY-MM-DD HH:mm:ss') : event.endDate;
    event.endDateSelected = event.endDateSelected ? moment(event.endDateSelected, 'DD.MM.YYYY').startOf('days').format('YYYY-MM-DD HH:mm:ss') : event.endDateSelected;
    event.endTime = moment(event.endTime, 'HH:mm').format('HH:mm:ss');
    event.startTime = moment(event.startTime, 'HH:mm').format('HH:mm:ss');
    event.startDateSelected = moment(event.startDateSelected, 'DD.MM.YYYY').startOf('days').format('YYYY-MM-DD HH:MM:SS');
    return createUpdateEvent(alarmPointId, event)
        .then(({data, status}) => {
            if (status === 200) {
                const {events, ...calendar} = data;
                context.commit('setCalendar', calendar);
                context.commit('setEvents', events);
                context.commit('setIsEditable', false);
                return true;
            } else {
                console.log('Something wrong with individual calendar');
                return false;
            }
        });
}

const callRemoveEvent = (context, {alarmpointId, sourceEventId}) => {
    return deleteEvent(alarmpointId, sourceEventId).then(({data, status}) => {
        if (status === 200) {
            const {events, ...calendar} = data;
            context.commit('setCalendar', calendar);
            context.commit('setEvents', events);
            context.commit('setIsEditable', false);
            return true;
        } else {
            console.log('Something wrong with individual calendar');
            return false;
        }
    });
}

export default {
    callCalendar,
    callCreateUpdateEvent,
    callRemoveEvent,
}
