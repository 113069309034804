import {LanguageEnum} from "@/enums/language.enum";
import {englishLocale, deutschLocale, frenchLocale, italianLocale} from "@/enums/calendar-locale.enum";

const storageLanguage = localStorage.getItem('lang');

export const localeSwitcher = () => {
    switch (storageLanguage) {
        case LanguageEnum.ENGLISH:
            return englishLocale;
        case LanguageEnum.GERMAN:
            return deutschLocale;
        case LanguageEnum.FRENCH:
            return frenchLocale;
        case LanguageEnum.ITALIAN:
            return italianLocale;
        default: return englishLocale;
    }
}