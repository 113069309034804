import {createApp, getAppByOrgId, removeAppById, updateApp} from "@/api/apps";
import {getList} from '@/api/list';
import {getAlarmPlan} from '@/api/alarmPlan';

const defaultIcon = {
    color: '#989898',
    name: 'mdi mdi-clock-alert-outline'
}

export default {
    callCreateApp: ({state, commit}) => {
        const app = {...state.app, icon: state.app.icon ? JSON.stringify(state.app.icon) : null}
        return createApp(app)
            .then(
                ({status, data}) => {
                    if (status === 200) {
                        try {
                            if (data.icon) {
                                data.icon = JSON.parse(data.icon)
                            } else {
                                data.icon = defaultIcon
                            }
                        } catch (e) {
                            data.icon = defaultIcon
                        }
                        commit('setApp', data)
                        commit('setIsEditable', false)
                        return Promise.resolve(data)
                    }
                    return Promise.reject(false)
                },
                error => {


                    localStorage.setItem('storedMessage', JSON.stringify({
                        content: error.response.data.reason,
                        severity: 'error',
                        life: 5000
                    }))

                    return Promise.reject(error.message)

                },
            )
    },
    callApp: ({state, commit, dispatch}, type) => {
        if (!type) return Promise.resolve()
        return getAppByOrgId(state.orgId, type)
            .then(
                ({status, data}) => {
                    if (status === 200) {
                        try {
                            if (data.icon) {
                                data.icon = JSON.parse(data.icon)
                            } else {
                                data.icon = defaultIcon
                            }
                        } catch (e) {
                            data = {
                                orgId: state.orgId,
                                systemId: state.orgId
                            }
                            data.icon = defaultIcon
                        }
                        commit('setIsEditable', false)
                        commit('setApp', data)
                        dispatch('callAlarmPlan')
                        return Promise.resolve(data)
                    }
                    return Promise.reject(false)
                },
                error => {

                    return Promise.reject(error.message)
                },
            )
    },
    callAlarmPlans: ({commit}, filter = '') => {
        return getList('ALARMPLAN', 0, filter, 1000)
            .then(({status, data}) => {
                    if (status === 200) {
                        commit('setAlarmPlans', data);
                        return Promise.resolve(data);
                    }
                    return Promise.reject(false);
                },
                error => {

                    return Promise.reject(error);
                })
    },
    callAlarmPlan: ({state, commit}) => {
        if (state.app && state.app.alarmPlanId) {
            return getAlarmPlan(state.app.alarmPlanId)
                .then(({status, data}) => {
                        if (status === 200) {
                            commit('setAlarmPlan', data);
                            return Promise.resolve(data);
                        }
                        return Promise.reject(false);
                    },
                    error => {

                        return Promise.reject(error);
                    })
        }
        return Promise.resolve(null)
    },
    callRemoveApp: ({state, commit}) => {
        const appId = state.app.id
        return removeAppById(appId)
            .then(
                ({status, data}) => {
                    if (status === 200) {
                        commit('setIsEditable', false)
                        commit('setApp', null)
                        return Promise.resolve(data)
                    }
                    return Promise.reject(false)
                },
                error => {

                    return Promise.reject(error.message)
                },
            )
    },
    callUpdateApp: ({state, commit}) => {
        const app = {...state.app, icon: state.app.icon ? JSON.stringify(state.app.icon) : null}
        return updateApp(app)
            .then(
                ({status, data}) => {
                    if (status === 200) {
                        try {
                            if (data.icon) {
                                data.icon = JSON.parse(data.icon)
                            } else {
                                data.icon = defaultIcon
                            }
                        } catch (e) {
                            data.icon = defaultIcon
                        }
                        commit('setIsEditable', false)
                        commit('setApp', data)
                        return Promise.resolve(data)
                    }
                    return Promise.reject(false)
                },
                error => {
                    localStorage.setItem('storedMessage', JSON.stringify({
                        content: error.response.data.reason,
                        severity: 'error',
                        life: 5000
                    }))
                    return Promise.reject(error.message)
                },
            )
    }
}
