import {deviceList} from '@/api/participant';

const callGetDevices = (context) => {
    deviceList().then(({data, status}) => {
        if (status === 200) {
            context.commit('setDevices', data);
        } else {
            console.log('Something wrong with devices');
        }
    })
}

export default {
    callGetDevices,
}
