const setDevices = (state, data) => {
    if (data) {
        state.devices = data;
    } else {
        state.devices = [];
    }
}
export default {
    setDevices,
}
