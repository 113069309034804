import * as _ from 'lodash';

const getParticipants = (state) => {
  return state.participants.list;
}

const getLoading = (state) => {
  return state.loading;
}

const getCount = (state) => {
  return state.participants.count;
}

const getCurrentPage = (state) => {
  return state.participants.currentPage;
}

const getPageSize = (state) => {
  return state.participants.pageSize;
}

const getPagesCount = (state) => {
  return state.participants.pagesCount;
}

const getSearch = (state) => {
  return state.search;
}

const getSelectedParticipants = state => {
  return _.uniqBy(state.selectedParticipants.filter(i => i), i => i.id);
}

const getCalendar = state => state.calendar;
const getEvents = state => state.events;
const getEvent = state => state.event;
const getIsEditable = state => state.isEditable;
const getRepeatTypes = state => state.repeatTypes;

export default {
  getParticipants,
  getLoading,
  getCount,
  getCurrentPage,
  getPageSize,
  getPagesCount,
  getSearch,
  getSelectedParticipants,
  getCalendar,
  getEvents,
  getEvent,
  getIsEditable,
  getRepeatTypes,
}