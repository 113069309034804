import request from "@/api/request";

export function getList(type, currentPage = 0, search = '', pageSize = 10, orgId = null, recursive = true) {
    if (orgId == null) {
        orgId = localStorage.getItem('orgId')
    }
    return request({
        url: `/list`,
        method: 'post',
        data: {
            currentPage: currentPage,
            filterValue: search,
            pageSize: pageSize,
            parentOrgId: orgId,
            recursive: recursive,
            type: type
        }
    })
}

export function prepare(ownerOrgId) {
    return request({
        url: `/alarmtemplates/prepare/${ownerOrgId}`,
        method: 'GET',
    })
}

export function create(data) {
    return request({
        url: '/alarmtemplates',
        method: 'POST',
        data
    })
}

export function update(data) {
    return request({
        url: '/alarmtemplates',
        method: 'PUT',
        data
    })
}

export function getItemById(id) {
    return request({
        url: `/alarmtemplates/${id}`,
        method: 'GET',
    })
}

export function removeItemById(id) {
    return request({
        url: `/alarmtemplates/${id}`,
        method: 'DELETE',
    })
}
