export default () => ({
    initiatorId: null,
    availableTriggers: [],
    triggers: [],
    orgId: null,
    mode: null, // null, 'new' or 'update'
    trigger: {
        name: '',
        acknowledgeMode: "NONE",
        alarmPlanId: null,
        alarmPointType: "CONSEQUENTIAL",
        confirmPriority: 1,
        consequentialTrigger: "ON_NEW",
        countRepeat: 0,
        deleted: true,
        description: "Description",
        id: null,
        icon: {
            name: '',
            color: '',
        },
        initiatorId: null,
        instructionFiles: [],
        localAcknowledgeTime: 1,
        mediaProcessingType: "BY_RANK",
        mediaUsageType: "ALL",
        orgId: null,
        orgName: '',
        repeatBetween: 0,
        repeatMonInterval: 0,
        rotationalCalendarId: null,
        speechText: '',
        text: '',
    },
    alarmPlans: {
        currentPage: 0,
        count: 0,
        list: [],
        pageSize: 20,
        pagesCount: 1
    },
    alarmPlan: null,
})
