import {
    getGeneralInfo,
    getMediaCalls,
    getMediaCallsByTypes,
    getResources,
    getStatistics,
    getWidgets,
    saveWidgets
} from '@/api/dashboard';
import {MediaCallsPeriodEnum} from '@/enums/media-calls-period.enum';
import {initialDashboardItems} from "@/constants/constants";

const callGetLines = (context) => {
    return getWidgets().then(({data, status}) => {
        if (status === 200 && data.widgets) {
            context.commit('setLines', JSON.parse(data.widgets));
            return JSON.parse(data.widgets);
        } else if(status === 200 && !data.widgets) {
            context.commit('setLines', initialDashboardItems);
            return initialDashboardItems;
        } else {
            console.log('Something wrong with widgets');
            return false;
        }
    })
}

const callSetLines = (context) => {
    return saveWidgets(JSON.stringify(context.state.lines)).then(({status}) => {
        if (status === 200) {
            context.commit('setIsEditable', false);
            return true;
        } else {
            console.log('Something wrong with widgets');
            return false;
        }
    })
}

const callGeneralInfo = (context, forceUpdate = false) => {
    if (!context.state.generalInfo || forceUpdate) {
        return getGeneralInfo()
            .then(({data, status}) => {
                if (status === 200) {
                    context.commit('setGeneralInfo', data);
                    return data;
                }
                return false;
            });
    }
}

const translatePeriod = (period) => {
    switch (period) {
        case MediaCallsPeriodEnum.MONTH_AGO:
            return MediaCallsPeriodEnum.MONTH_AGO.toLowerCase();
        case MediaCallsPeriodEnum.MONTH_CURRENT:
            return MediaCallsPeriodEnum.MONTH_CURRENT.toLowerCase();
        case MediaCallsPeriodEnum.MONTH_PREVIOUS:
            return MediaCallsPeriodEnum.MONTH_PREVIOUS.toLowerCase();
        case MediaCallsPeriodEnum.TODAY:
            return MediaCallsPeriodEnum.TODAY.toLowerCase();
        case MediaCallsPeriodEnum.WEEK_AGO:
            return MediaCallsPeriodEnum.WEEK_AGO.toLowerCase();
        case MediaCallsPeriodEnum.WEEK_CURRENT:
            return MediaCallsPeriodEnum.WEEK_CURRENT.toLowerCase();
        default:
            return MediaCallsPeriodEnum.TODAY.toLowerCase();
    }
}

const callMediaCallsByTypes = (context, forceUpdate = false) => {
    if (!Object.keys(context.state.mediaCallsByTypes.items).length || forceUpdate) {
        return getMediaCallsByTypes(translatePeriod(context.state.mediaCallsByTypes.period))
            .then(({data, status}) => {
                if (status === 200) {
                    context.commit('setMediaCallsByTypes', data);
                    return data;
                }
                return false;
            });
    }
}

const callMediaCalls = (context, forceUpdate = false) => {
    if (!Object.keys(context.state.mediaCalls.items).length || forceUpdate) {
        return getMediaCalls(translatePeriod(context.state.mediaCalls.period))
            .then(({data, status}) => {
                if (status === 200) {
                    context.commit('setMediaCalls', data);
                    return data;
                }
                return false;
            });
    }
}

const callStatistics = (context, forceUpdate = false) => {
    if (!Object.keys(context.state.statistics.items).length || forceUpdate) {
        return getStatistics(translatePeriod(context.state.statistics.period))
            .then(({data, status}) => {
                if (status === 200) {
                    context.commit('setStatistics', data);
                    return data;
                }
                return false;
            });
    }
}

const callResources = (context, forceUpdate = false) => {
    if (!context.state.resources || forceUpdate) {
        return getResources().then(({data, status}) => {
            if (status === 200) {
                context.commit('setResources', data);
                return data;
            }
            return false;
        })
    }
}

export default {
    callGetLines,
    callSetLines,
    callGeneralInfo,
    callMediaCallsByTypes,
    callMediaCalls,
    callStatistics,
    callResources,
}
