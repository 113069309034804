const getLocale = (state) => {
  return state.locale;
}

const trans = (state) => (textConst) => {
  return state.locale[textConst] ?? textConst;
}

const getLanguages = (state) => {
  return state.languages;
}

export default {
  getLocale,
  trans,
  getLanguages,
}