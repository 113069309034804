import * as moment from 'moment-timezone';

const setCalendar = (state, data) => {
  if (data) {
    state.calendar = data;
  } else {
    state.calendar = null;
  }
}

const setEvents = (state, data) => {
  if (data) {
    state.events = data.map(event => ({
      ...event,
      endDate: event.endDate ? moment(event.endDate, 'YYYY-MM-DD HH:mm:ss').startOf('days').format('YYYY-MM-DD') : event.endDate,
      endDateSelected: moment(event.endDateSelected, 'YYYY-MM-DD HH:mm:ss').startOf('days').format('DD.MM.YYYY'),
      endTime: moment(event.endTime, 'HH:mm:ss').format('HH:mm'),
      startTime: moment(event.startTime, 'HH:mm:ss').format('HH:mm'),
      startDateSelected: moment(event.startDateSelected, 'YYYY-MM-DD HH:mm:ss').startOf('days').format('DD.MM.YYYY'),
    }));
  } else {
    state.events = [];
  }
}

const addEvent = (state, event) => {
  state.events.push(event);
}

const setEvent = (state, data) => {
  if (data) {
    state.event = data;
  } else {
    state.event = null;
  }
}

const selectEvent = (state, sourceEventId) => {
  state.event = state.events.find(i => i.sourceEventId === sourceEventId);
}

const setIsEditable = state => state.isEditable = true;

const createEmptyEvent = state => {
  state.isEditable = false;
  state.event = {
    acknowledgeTime: 1,
    active: true,
    callingNumber: null,
    countAfterTimesEnd: null,
    day: moment().format('YYYY-MM-DD'),
    deviceType: null,
    endDate: moment().format('YYYY-MM-DD'),
    endDateSelected: moment().format('DD.MM.YYYY'),
    endTime: '23:59',
    endType: "AFTER_TIMES_END",
    markedDays: null,
    monthSubType: "DAY_OF_THE_MONTH",
    recurring: false,
    repeatEvery: 1,
    repeatType: "DAILY",
    ruleId: null,
    sourceEventId: null,
    startDateSelected: moment().format('DD.MM.YYYY'),
    startTime: '00:00',
    title: null,
  }
}

export default {
  setCalendar,
  setEvents,
  addEvent,
  setEvent,
  selectEvent,
  setIsEditable,
  createEmptyEvent,
}
