import request from "@/api/request";

export const createItem = data => {
    return request({
        baseURL: process.env.VUE_APP_BASE_API,
        url: `/consequentials`,
        method: 'POST',
        data,
    })
}

export const updateItem = data => {
    return request({
        baseURL: process.env.VUE_APP_BASE_API,
        url: `/consequentials`,
        method: 'PUT',
        data,
    })
}

export const getItem = id => {
    return request({
        baseURL: process.env.VUE_APP_BASE_API,
        url: `/consequentials/${id}`,
        method: 'GET',
    })
}

export const deleteItem = id => {
    return request({
        baseURL: process.env.VUE_APP_BASE_API,
        url: `/consequentials/${id}`,
        method: 'DELETE',
    })
}

export const getList = initiatorId => {
    return request({
        baseURL: process.env.VUE_APP_BASE_API,
        url: `/consequentials/list?initiatorId=${initiatorId}`,
        method: 'GET',
    })
}

export const getAlias = triggerType => {
    return request({
        baseURL: process.env.VUE_APP_BASE_API,
        url: `/consequentials/show-name?triggerType=${triggerType}`,
        method: 'PUT',
    })
}

export const getAvailableTriggers = initiatorId => {
    return request({
        baseURL: process.env.VUE_APP_BASE_API,
        url: `/consequentials/triggers/${initiatorId}`,
        method: 'GET',
    })
}
