export default () => {
    return {
        alarms: [],
        alarm: null,
        meta: {
            count: 0,
            currentPage: 0,
            pageSize: 10,
            pagesCount: 1,
            columnsFilter: {
                alarmName: null,
                alarmPointId: "",
                status: null,
                systemId: null,
                triggeredBy: null,
            },
            duration: {
                start: null,
                end: null,
                hours: 23,
                option: "HOURS_DURATION"
            },
            organizationFilter: [
                {
                    id: "1",
                    data: [
                        {
                            label: "Total system",
                        }
                    ]
                }
            ],
        },
    }
}
