import request from "@/api/request";

export function getTree(id, params = '') {
    const query = id ? `/${id}` : ''
    return request({
        url: `/tree${query}?${params}`,
        method: 'get',
    })
}

export function getTreeExtended(id, objTypes = [], fullData = true) {
    let data = {
        id: id,
        fullData: fullData,
//        type: objTypes
        type: objTypes[0] ?? 'PARTICIPANT'
    }
    return request({
        url: `/extended`,
        method: 'post',
        data: data
    })
}
