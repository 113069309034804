import {MediaCallsPeriodEnum} from '@/enums/media-calls-period.enum';

export default () => {
    return {
        lines: [[], [], [], [], [], [], [], [], [], [], [], []],
        isLoading: false,
        isEditable: false,
        generalInfo: null,
        mediaCallsByTypes: {
            period: MediaCallsPeriodEnum.TODAY,
            periods: [
                {key: MediaCallsPeriodEnum.TODAY, label: 'Today'},
                {key: MediaCallsPeriodEnum.WEEK_CURRENT, label: 'Current week'},
                {key: MediaCallsPeriodEnum.WEEK_AGO, label: 'Last 7 days'},
                {key: MediaCallsPeriodEnum.MONTH_CURRENT, label: 'Current month'},
                {key: MediaCallsPeriodEnum.MONTH_PREVIOUS, label: 'Previous month'},
                {key: MediaCallsPeriodEnum.MONTH_AGO, label: 'Last 30 days'},
            ],
            items: {},
        },
        mediaCalls: {
            period: MediaCallsPeriodEnum.TODAY,
            periods: [
                {key: MediaCallsPeriodEnum.TODAY, label: 'Today'},
                {key: MediaCallsPeriodEnum.WEEK_CURRENT, label: 'Current week'},
                {key: MediaCallsPeriodEnum.WEEK_AGO, label: 'Last 7 days'},
                {key: MediaCallsPeriodEnum.MONTH_CURRENT, label: 'Current month'},
                {key: MediaCallsPeriodEnum.MONTH_PREVIOUS, label: 'Previous month'},
                {key: MediaCallsPeriodEnum.MONTH_AGO, label: 'Last 30 days'},
            ],
            items: {},
        },
        statistics: {
            period: MediaCallsPeriodEnum.TODAY,
            periods: [
                {key: MediaCallsPeriodEnum.TODAY, label: 'Today'},
                {key: MediaCallsPeriodEnum.WEEK_CURRENT, label: 'Current week'},
                {key: MediaCallsPeriodEnum.WEEK_AGO, label: 'Last 7 days'},
                {key: MediaCallsPeriodEnum.MONTH_CURRENT, label: 'Current month'},
                {key: MediaCallsPeriodEnum.MONTH_PREVIOUS, label: 'Previous month'},
                {key: MediaCallsPeriodEnum.MONTH_AGO, label: 'Last 30 days'},
            ],
            items: {},
        },
        resources: null,
    }
}
