import {create, getItemById, getList, prepare, removeItemById, update} from '@/api/alarm-templates';

const callList = (context, orgId, search = '') => {
    return getList(
        'ALARM_TEMPLATE',
        context.state.meta.currentPage,
        search,
        context.state.meta.pageSize,
        orgId,
    )
        .then(
            ({data, status}) => {
                if (status === 200) {
                    context.commit('setTemplates', data);
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(false);
                }
            },
            error => {
                return Promise.reject(error.message);
            },
        );
}

const callItem = (context, id) => {
    return getItemById(id)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    try {
                        data = {...data, icon: JSON.parse(data.icon)}
                    } catch (e) {
                        data = {...data, icon: null}
                    }
                    context.commit('setTemplate', data);
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(false);
                }
            },
            error => {
                return Promise.reject(error.message);
            },
        );
}

const callPrepare = (context, ownerOrgId) => {
    return prepare(ownerOrgId)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    try {
                        data = {...data, icon: JSON.parse(data.icon)}
                    } catch (e) {
                        data = {...data, icon: null}
                    }
                    context.commit('setTemplate', data);
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(false);
                }
            },
            error => {
                return Promise.reject(error.message);
            },
        );
}

const callCreate = (context) => {
    context.state.template.icon = context.state.template.icon ? JSON.stringify(context.state.template.icon) : null
    return create(context.state.template)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    try {
                        data = {...data, icon: JSON.parse(data.icon)}
                    } catch (e) {
                        data = {...data, icon: null}
                    }
                    context.commit('setTemplate', data);
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(false);
                }
            },
            error => {
                return Promise.reject(error.message);
            },
        );
}

const callUpdate = (context) => {
    context.state.template.icon = context.state.template.icon ? JSON.stringify(context.state.template.icon) : null
    return update(context.state.template)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    try {
                        data = {...data, icon: JSON.parse(data.icon)}
                    } catch (e) {
                        data = {...data, icon: null}
                    }
                    context.commit('setTemplate', data);
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(false);
                }
            },
            error => {
                return Promise.reject(error.message);
            },
        );
}

const callRemove = (context) => {
    const templateId = context.state.template.id;
    return removeItemById(templateId)
        .then(
            ({data, status}) => {
                if (status === 200) {
                    context.commit('removeTemplate');
                    return Promise.resolve(data);
                } else {
                    return Promise.reject(false);
                }
            },
            error => {
                return Promise.reject(error.message);
            },
        );
}

export default {
    callList,
    callItem,
    callPrepare,
    callCreate,
    callUpdate,
    callRemove,
}
