import {createStore} from 'vuex';
import actions from '@/store/actions';
import getters from '@/store/getters';
import mutations from '@/store/mutations';
import state from '@/store/state';
import modules from '@/store/modules';

export default createStore({
    modules,
    state,
    actions,
    getters,
    mutations,
})
