import request from "@/api/request";

export const getInstructionFiles = (filter = '') => {
    return request({
        baseURL: process.env.VUE_APP_ADMIN_API,
        url: `/instructions/instruction-files?filter=${filter}`,
        method: 'get',
    })
}

export const removeInstructionFiles = data => {
    return request({
        baseURL: process.env.VUE_APP_ADMIN_API,
        url: `/instructions/delete`,
        method: 'delete',
        data,
    })
}

export const moveInstructionFiles = (data, orgId) => {
    return request({
        baseURL: process.env.VUE_APP_ADMIN_API,
        url: `/instructions/move?orgId=${orgId}`,
        method: 'patch',
        data,
    })
}

export const uploadInstructionFile = formData => {
    return request({
        baseURL: process.env.VUE_APP_ADMIN_API,
        url: '/instructions/upload',
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const multiplyUploadInstructionFiles = formData => {
    return request({
        baseURL: process.env.VUE_APP_ADMIN_API,
        url: '/instructions/upload-files',
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
