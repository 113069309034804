import request from "@/api/request";

export function getTableFilters() {
    return request({
        url: `/table-filters`,
        method: 'get',
    })
}

export function setTableFilters(data) {
    return request({
        url: '/table-filters',
        method: 'post',
        data,
        headers: {
            'Content-type': 'text/plain'
        }
    })
}

export function deleteTableFilters() {
    return request({
        url: `/table-filters`,
        method: 'delete',
    })
}
