import {RepeatTypeEnum} from '@/enums/repeat-type.enum';

export default () => {
  return {
    loading: false,
    selectedParticipants: [],
    participants: {
      list: [],
      count: 0,
      currentPage: 0,
      pageSize: 100,
      pagesCount: 0,
    },
    search: '',
    events: [],
    event: null,
    calendar: null,
    isEditable: false,
    repeatTypes: Object.keys(RepeatTypeEnum).map(i => ({
      value: i,
      label: i.charAt(0).toUpperCase() + i.toLowerCase().slice(1),
    })),
  }
}
