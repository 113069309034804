import moment from "moment-timezone";
import request from "@/api/request";
import {ListTypeEnum} from '@/enums/list-type.enum';
import {OrderDirectionEnum} from '@/enums/order-direction.enum';

export const getListCalendars = (orgId, filterValue = '', currentPage = 0, pageSize = 100) => {
    return request({
        url: `/list`,
        method: 'post',
        data: {
            parentOrgId: orgId,
            type: ListTypeEnum.ALARM_ROTATIONAL,
            pageSize,
            currentPage,
            filterValue,
            recursive: true,
            sortField: 'name',
            sortOrder: OrderDirectionEnum.ASC,
        }
    })
}

export const getCalendarById = calendarId => {
    return request({
        url: `/alarm-rotationals/${calendarId}`,
        method: 'get',
    })
}

export const getListEvents = (calendarId) => {
    return request({
        url: `/alarm-rotationals/${calendarId}`,
        method: 'get',
    })
}

export const getListAlarmPlans = (orgId, filterValue = '', currentPage = 0, pageSize = 2000) => {
    return request({
        url: `/list`,
        method: 'post',
        data: {
            parentOrgId: orgId,
            type: ListTypeEnum.ALARMPLAN,
            pageSize,
            currentPage,
            filterValue,
            recursive: true,
            sortField: 'name',
            sortOrder: OrderDirectionEnum.ASC,
        }
    })
}

export const createCalendar = (calendar, weekGroups = []) => {
    return request({
        url: `/alarm-rotationals`,
        method: 'post',
        data: {
            ...calendar,
            alarmPointWeekGroups: weekGroups.map(g => ({
                ...g,
                alarmPointRotationEvents: g.alarmPointRotationEvents.map(e => ({
                    ...e,
                    dateRule: {
                        ...e.dateRule,
                        start: moment(e.dateRule.start, 'HH:mm').format('HH:mm:ss'),
                        end: moment(e.dateRule.end, 'HH:mm').format('HH:mm:ss'),
                    }
                }))
            })),
        },
    })
}

export const updateCalendar = (calendar, weekGroups = []) => {
    return request({
        url: `/alarm-rotationals`,
        method: 'put',
        data: {
            ...calendar,
            alarmPointWeekGroups: weekGroups.map(g => ({
                ...g,
                alarmPointRotationEvents: g.alarmPointRotationEvents.map(e => ({
                    ...e,
                    dateRule: {
                        ...e.dateRule,
                        start: moment(e.dateRule.start, 'HH:mm').format('HH:mm:ss'),
                        end: moment(e.dateRule.end, 'HH:mm').format('HH:mm:ss'),
                    }
                }))
            })),
        },
    })
}

export const removeCalendar = (calendarId) => {
    return request({
        url: `/alarm-rotationals/${calendarId}`,
        method: 'delete',
    })
}

export const getCalendarAssignment = (calendarId, page = 0, pageSize = 20) => {
    return request({
        url: `/list/membership`,
        method: 'POST',
        data: {
            "currentPage": page,
            "objectId": calendarId,
            "pageSize": pageSize,
            "referenceType": "ALARMPOINT",
            "type": "ALARM_ROTATIONAL"
        },
    })
}

export const cloneCalendar = (data) => {
    return request({
        url: `/alarm-rotationals/clone`,
        method: 'post',
        data,
    })
}
