const getCalendars = (state) => {
  return state.calendars;
}

const getCalendar = (state) => {
  return state.calendar;
}

const getCalendarMeta = (state) => {
  return state.calendarMeta;
}

const getEvents = (state) => {
  return state.events;
}

const getEvent = (state) => {
  return state.event;
}

const getAlarmPlans = (state) => {
  return state.alarmPlans;
}

const getAlarmPlanById = (state) => id => {
  return state.alarmPlans.find(i => i.id === id);
}

/* ASSIGNMENT */
const getAssignment = state => {
  return state.assignment;
}

const getIsEditable = state => state.isEditable;

const getLoadingCalendars = (state) => {
  return state.loadingCalendars;
}

const getLoadingEvents = (state) => {
  return state.loadingEvents;
}

export default {
  getCalendars,
  getCalendar,
  getCalendarMeta,

  getEvents,
  getEvent,

  getAlarmPlans,
  getAlarmPlanById,

  getAssignment,

  getIsEditable,

  getLoadingCalendars,
  getLoadingEvents,
}
