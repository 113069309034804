import {RepeatTypeEnum} from '@/enums/repeat-type.enum';

export default () => {
    return {
        events: [],
        event: null,
        calendar: null,
        isEditable: false,
        repeatTypes: Object.keys(RepeatTypeEnum).map(i => ({
            value: i,
            label: i.charAt(0).toUpperCase() + i.toLowerCase().slice(1),
        })),
    }
}
