export default {
    setToken(state, payload) {
        state.token = payload
        localStorage.setItem("token", payload)
    },
    clearToken(state) {
        state.token = null
        localStorage.removeItem("token")
    },
};
