import request from "@/api/request";

export function login(data) {
    return request({
            url: `/auth`,
            method: 'post',
            data,
            headers: {}
        }
    )
}

export function logout() {
    return request({
            url: `/logout`,
            method: 'get',
        }
    )
}

export function refresh() {
    return request({
            url: `/refresh`,
            method: 'post',
            data: {
                refreshToken: localStorage.getItem('authtoken')
            }
        }
    )
}

export function generateQrUrl(data) {
    return request({
            url: `/generate-qr-url`,
            method: 'post',
            data,
        }
    )
}

export function reset2faStatus(userName) {
    return request({
            url: `/reset-2fa-status?userLogin=${userName}`,
            method: 'patch',
        }
    )
}

export function get2faStatus(userName) {
    return request({
            url: `/user-2fa-status?userLogin=${userName}`,
            method: 'get',
        }
    )
}

export function getGlobalColumnsOrderPermission(token) {
    return request({
            url: `/ui-columns-order/is-allowed`,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    )
}
