import request from "@/api/request";

export function getAlarmPlan(id) {
    return request({
        url: `/alarmplans/${id}`,
        method: 'get'
    })
}

export function deleteAlarmPlan(id) {
    return request({
        url: `/alarmplans/${id}`,
        method: 'delete'
    })
}


export function updateAlarmPlan(data) {
    return request({
        url: `/alarmplans`,
        method: 'put',
        data: data
    })
}

export function createAlarmPlan(data) {
    return request({
        url: `/alarmplans`,
        method: 'post',
        data: data
    })
}

export function cloneAlarmPlan(data) {
    return request({
        url: `/alarmplans/clone`,
        method: 'post',
        data,
    })
}

export function getAlarmPlanAlarmPoints(id, search = '', type = 'ALARMPLAN', refType = 'ALARMPOINT') {
    const data = {
        type,
        objectId: id,
        referenceType: refType,
        currentPage: 0,
        filterValue: search,
        pageSize: 10000
    }

    return request({
        url: `/list/membership`,
        method: 'post',
        data: data
    })
}
