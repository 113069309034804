<template>
  <div>
    <Button v-if="filtered" v-tooltip="_t('label_include_child_orgs')"
            class="p-button-rounded p-button-icon-only p-button-text" icon="medium-icon mdi mdi-account-multiple-plus"
            @click="removeFilter"/>
    <Button v-else v-tooltip="_t('label_exclude_child_orgs')"
            class="p-button-rounded p-button-icon-only p-button-text" icon="medium-icon mdi mdi-account-multiple-minus"
            @click="applyFilter"/>
  </div>
</template>
<style>
span.medium-icon {
  font-size: 17px !important;
}
</style>
<script>
export default {
  name: 'ChildFilter',
  props: {
    modelValue: {
      type: Array
    },
    orgId: {
      type: [Number, String],
      default: 1
    },
    children: {
      type: [String],
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      filtered: false,
      original: [],
    }
  },
  created() {
    this.original = this.modelValue
  },
  methods: {
    refresh(newValue) {
      this.original = newValue

      if (this.filtered) {
        this.applyFilter()
      }
    },
    applyFilter() {
      if (!Array.isArray(this.original)) {
        return
      }

      const list = Array.from(this.original)

      let filtered = []
      if (this.children && this.children.length) {
        list.forEach((m) => {
          let result = m.orgId == '*' || m.orgId == this.orgId
          if (result) {
            if (m[this.children] && m[this.children].length > 0) {
              m[this.children] = m[this.children].filter(c => c.orgId == '*' || c.orgId == this.orgId)
              if (m[this.children].length > 0) {
                filtered.push(m)
              }
            } else {
              filtered.push(m)
            }
          }
        }, this)
      } else {
        filtered = list.filter(m => m.orgId == '*' || m.orgId == this.orgId)
      }

      this.$emit('update:modelValue', filtered)
      this.filtered = true
    },
    removeFilter() {
      this.$emit('update:modelValue', this.original)
      this.filtered = false
    }
  }
}
</script>
