import request from "@/api/request";

export function getWidgets() {
    return request({
        url: `/widgets`,
        method: 'get',
    });
}

export function saveWidgets(widgets) {
    return request({
        url: `/widgets`,
        method: 'put',
        data: {
            widgets: JSON.stringify(widgets)
        },
    });
}

export function removeWidgets() {
    return request({
        url: `/widgets`,
        method: 'delete',
    });
}

export function getGeneralInfo() {
    return request({
        url: `/dashboard`,
        method: 'get',
    });
}

export function getMediaCallsByTypes(period) {
    return request({
        url: `/dashboard/calls-typed/${period}`,
        method: 'get',
    });
}

export function getMediaCalls(period) {
    return request({
        url: `/dashboard/calls/${period}`,
        method: 'get',
    });
}

export function getStatistics(period) {
    return request({
        url: `/dashboard/statistics/${period}`,
        method: 'get',
    });
}

export function getResources() {
    return request({
        url: `/dashboard/resources`,
        method: 'get',
    });
}
