import { getParticipantCalendar, createUpdateEvent, deleteEvent } from '@/api/participant';
import * as moment from "moment-timezone";

const callCalendar = (context, participantId) => {
  if (participantId) {
    return getParticipantCalendar(participantId).then(({ data, status }) => {
      if (status === 200) {
        const { events, ...calendar } = data;
        context.commit('setCalendar', calendar);
        context.commit('setEvents', events);
        return true;
      } else {
        console.log('Something wrong with individual calendar');
        return false;
      }
    });
  }
}

const callCreateUpdateEvent = (context, participantId) => {
  let event = context.state.event;
  event.title = event.title ?? 'Event title';
  event.endDate = event.endDate ? moment(event.endDate, 'YYYY-MM-DD').startOf('days').format('YYYY-MM-DD HH:mm:ss') : event.endDate;
  event.endDateSelected = event.endDateSelected ? moment(event.endDateSelected, 'DD.MM.YYYY').startOf('days').format('YYYY-MM-DD HH:mm:ss'): event.endDateSelected;
  event.endTime = moment(event.endTime, 'HH:mm').format('HH:mm:ss');
  event.startTime = moment(event.startTime, 'HH:mm').format('HH:mm:ss');
  event.startDateSelected = moment(event.startDateSelected, 'DD.MM.YYYY').startOf('days').format('YYYY-MM-DD HH:mm:ss');
  return createUpdateEvent(participantId, event).then(({ data, status }) => {
    if (status === 200) {
      const { events, ...calendar } = data;
      context.commit('setCalendar', calendar);
      context.commit('setEvents', events);
      context.commit('setIsEditable', false);
      return true;
    } else {
      return Promise.reject(false);
    }
  })
    .catch(err => {
      console.log('Something wrong with individual calendar');
      event.endDate = event.endDate ? moment(event.endDate, 'YYYY-MM-DD HH:mm:ss').startOf('days').toDate() : event.endDate;
      event.endDateSelected = event.endDateSelected ? moment(event.endDateSelected, 'YYYY-MM-DD HH:mm:ss').startOf('days').toDate(): event.endDateSelected;
      event.endTime = moment(event.endTime, 'HH:mm:ss').format('HH:mm');
      event.startTime = moment(event.startTime, 'HH:mm:ss').format('HH:mm');
      event.startDateSelected = moment(event.startDateSelected, 'YYYY-MM-DD HH:mm:ss').startOf('days').toDate();
      return Promise.reject(err);
    });
}

const callRemoveEvent = (context, { participantId, sourceEventId  }) => {
  return deleteEvent(participantId, sourceEventId).then(({ data, status }) => {
    if (status === 200) {
      const { events, ...calendar } = data;
      context.commit('setCalendar', calendar);
      context.commit('setEvents', events);
      context.commit('setIsEditable', false);
      return true;
    } else {
      console.log('Something wrong with individual calendar');
      return false;
    }
  });
}

export default {
  callCalendar,
  callCreateUpdateEvent,
  callRemoveEvent,
}
