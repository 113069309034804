export default () => {
    return {
        calendars: [],
        calendar: {},
        calendarMeta: {
            count: 0,
            currentPage: 0,
            pageSize: 20,
            pagesCount: 0,
        },
        assignment: [],
        assignmentMeta: {
            count: 0,
            currentPage: 0,
            pageSize: 20,
            pagesCount: 0,
        },
        alarmPlans: [],
        alarmPlanMeta: {
            count: 0,
            currentPage: 0,
            pageSize: 20,
            pagesCount: 0,
        },
        events: [],
        event: null,
        isEditable: false,
        loadingCalendars: false,
        loadingEvents: false
    }
}
