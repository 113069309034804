import { loadLocale } from '@/api/locale';

const getLocale = (context) => {
  loadLocale()
    .then(({ data, status }) => {
      if (status === 200) {
        context.commit('setLocale', data);
      } else {
        console.log('Something wrong with locale');
      }
    });
}

export default {
  getLocale
}